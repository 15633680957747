import React from 'react';

export default function TextHelper() {
  return (
    <small
      style={{
        fontFamily: 'sans-serif',
        fontSize: 10,
        color: '#4a4a4a'
      }}
    >
      <font
        color="#08318e"
        style={{ fontWeight: 700 }}
      >
        ENTER{' '}
      </font>
      para pesquisar
    </small>
  )
}
