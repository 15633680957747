import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Info from '@material-ui/icons/Info';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import DialogAcoesIndicacao from '../TodasIndicacoes/DialogAcoesIndicacao';

import api from 'src/services/data';

export default function Pendentes() {
    const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
    
    const [indicacoes, setIndicacoes] = useState([]);
    
    const [indicacaoSelecionada, setIndicacaoSelecionada] = useState('');
    const [openDialogAcoesIndicacoes, setOpenDialogAcoesIndicacoes] = useState(false);

    useEffect(() => {
        api.get(`api/acesso/indicacoes/busca-por-usuario/${sessao?.user_block}/fechado`)
        .then(({ data }) => {
            const dataFormat = data.map((indicacao) => {
                return {
                    cliente: indicacao.pessoa.razao_nome,
                    status: <Typography className="status fechado">Fechado</Typography>,
                    comissao: `R$ ${Number(indicacao.valor_comissao).toFixed(2)}`,
                    btnActions: (
                        <center>
                            <IconButton
                                title="Detalhes"
                                style={{ color: '#0e3e94' }}
                                onClick={() => {
                                    setIndicacaoSelecionada(indicacao.id);
                                    setOpenDialogAcoesIndicacoes(true);
                                }}
                            >
                                <Info />
                            </IconButton>
                        </center>
                    )
                }
            });

            setIndicacoes(dataFormat);
        }).catch(console.log)
    }, []);

    return (
        <Container maxWidth="xl">
            <DataTable
                value={indicacoes}
                rows={15}
                paginator
                responsive
                style={{ textAlign: 'center' }}
            >
                <Column
                    header="Cliente"
                    field="cliente"
                    filter
                    filterMatchMode="contained"
                />

                <Column
                    header="Comissão R$"
                    field="comissao"
                />

                <Column
                    header="Status"
                    field="status"
                />

                <Column
                    header="Ações"
                    field="btnActions"
                />
            </DataTable>

            <DialogAcoesIndicacao
                open={openDialogAcoesIndicacoes}
                idIndicacao={indicacaoSelecionada}
                setOpen={setOpenDialogAcoesIndicacoes}
                setIdIndicacao={setIndicacaoSelecionada}
            />
        </Container>
    )
}