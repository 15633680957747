import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function DetalhesPermissoes({ nome, vis, cad, edi, del }) {
  return (
    <Box className="boxInfoTwo">
      <Box style={{ borderBottom: '1px dashed #08318e' }}>
        <Typography
          style={{ color: '#08318e', fontSize: 16, fontWeight: 'bold' }}
        >
          {nome}
        </Typography>
      </Box>

      <Box className="boxInfo">
        <Typography className="textInfo">Visualizar:</Typography>
        <Typography className="fontInfo">
          {vis ? 'Permitido' : 'Negado'}
        </Typography>
      </Box>

      <Box className="boxInfo">
        <Typography className="textInfo">Cadastrar:</Typography>
        <Typography className="fontInfo">
          {cad ? 'Permitido' : 'Negado'}
        </Typography>
      </Box>

      <Box className="boxInfo">
        <Typography className="textInfo">Editar:</Typography>
        <Typography className="fontInfo">
          {edi ? 'Permitido' : 'Negado'}
        </Typography>
      </Box>

      <Box className="boxInfo">
        <Typography className="textInfo">Deletar:</Typography>
        <Typography className="fontInfo">
          {del ? 'Permitido' : 'Negado'}
        </Typography>
      </Box>
    </Box>
  );
}
