import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Dialog, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { Lock, LockOpen, Security, Settings, Visibility, VisibilityOff } from '@material-ui/icons';
import {useSnackbar} from 'notistack';
import showSnack from '../../utils/snacks';
import api from '../../services/data';

function AtualizarSenha() {
    const needPassword = JSON.parse(sessionStorage.getItem('@N#P#U@'));
    const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
    const { enqueueSnackbar } = useSnackbar();

    const [openAtualizar, setOpenAtualizar] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const upPassword = () => {
        if(password && repeatPassword) {
            if(password === repeatPassword) {
                if(sessao?.user_block && sessao?.lock) {
                    api.put(
                        `api/acesso/atualizar/passwordNeed/${sessao.user_block}`,
                        {
                          senha: password
                        }
                      )
                      .then(({data}) => {
                        sessionStorage.removeItem('@N#P#U@');
                        showSnack(data?.message, enqueueSnackbar);
                        setOpenAtualizar(false);
                      })
                } else {
                    showSnack('Ocorreu um erro, necessário informar ao suporte!', enqueueSnackbar, 'warning');
                }
            } else {
                showSnack('As senhas digitas não conferem!', enqueueSnackbar, 'info');
            }
        } else {
            showSnack('Necessário os campos nova senha e redigite a nova senha!', enqueueSnackbar, 'info');
        }
    };

    useEffect(() => {
        if(needPassword?.need === 1) {
            setOpenAtualizar(true);
        } else {
            setOpenAtualizar(false);
        }
    }, []);

    return (
        <Dialog
            open={openAtualizar}
            fullScreen
        >
            <DialogContent style={{ background: '#2a374c' }}>
                <Container
                    maxWidth="md"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Box
                        style={{
                            background: '#ebecec',
                            padding: 10,
                            width: '100%',
                            height: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                            borderRadius: 4
                        }}
                    >
                        <Typography
                            style={{
                                color: '#2a374c',
                                fontWeight: 'bold',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Lock style={{ fontSize: '5rem', marginBottom: 16 }}/>
                            Para continuar a navegação é necessário atualizar a sua senha:
                        </Typography>

                        <FormControl variant="outlined" fullWidth style={{ marginTop: 10 }} required>
                            <InputLabel htmlFor="outlined-adornment-password">Nova Senha:</InputLabel>
                            <OutlinedInput
                                id="input-with-text-normal"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={115}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth style={{ marginTop: 10 }} required>
                            <InputLabel htmlFor="outlined-adornment-password">Redigite a Nova Senha:</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPasswordRepeat ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                                            edge="end"
                                        >
                                            {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={200}
                                value={repeatPassword}
                                onChange={(e) => {
                                    setRepeatPassword(e.target.value);
                                }}
                            />
                        </FormControl>

                        <Button
                            variant="contained"
                            style={{ background: '#08318e', color: '#f2f4f5' }}
                            onClick={() => {
                                upPassword();
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
}

export default AtualizarSenha;
