import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    background: '#d95c00',
    marginTop: 10,
    cursor: 'pointer'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const Pausados = ({ valor, className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      onClick={() => {
        history.push('/app/atendimentos/pausado');
      }}
    >
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ color: '#f2f4f5' }} gutterBottom variant="h6">
              Atendimentos Pausados
            </Typography>
            <Typography style={{ color: '#f2f4f5' }} variant="h1">
              {valor}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Pausados.propTypes = {
  className: PropTypes.string
};

export default Pausados;
