import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Add from '@material-ui/icons/Add';
import Info from '@material-ui/icons/Info';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import DialogCriar from './DialogCriar';
import DialogDetalhes from './DialogDetalhes';
import DialogEditar from './DialogEditar';
import DialogDeletar from './DialogDeletar';

import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';

import api from 'src/services/data';

export default function StatusIndicacao() {
    const { enqueueSnackbar } = useSnackbar();

    const [listStatus, setListStatus] = useState([]);

    const [reload, setReload] = useState(false);
    const [openDialogCad, setOpenDialogCad] = useState(false);
    const [openDialogDetalhes, setOpenDialogDetalhes] = useState(false);
    const [openDialogEditar, setOpenDialogEditar] = useState(false);
    const [openDialogDeletar, setOpenDialogDeletar] = useState(false);
    const [idStatus, setIdStatus] = useState('');

    useEffect(() => {
        api.get('api/acesso/status-indicacao')
        .then(({ data }) => {
            const getAllStatusFormated =  data.map((status) => {
                return {
                    ...status,
                    cor: (
                        <Box
                            style={{
                                background: status.cor,
                                width: '100%',
                                height: '1rem',
                            }}
                        />
                    ),
                    status_abertura: status.status_abertura ? 'Sim' : '-',
                    status_final: status.status_final ? 'Sim' : '-',
                    necessario_obs: status.necessario_obs ? 'Sim' : '-',
                    btnAcoes: (
                        <Box>
                            <IconButton
                                style={{ color: '#6420cfd9' }}
                                onClick={() => {
                                    setIdStatus(status.id);
                                    setOpenDialogDetalhes(true);
                                }}
                            >
                                <Info />
                            </IconButton>

                            <IconButton
                                style={{ color: '#e26912' }}
                                onClick={() => {
                                    setIdStatus(status.id);
                                    setOpenDialogEditar(true);
                                }}
                            >
                                <Edit />
                            </IconButton>

                            <IconButton
                                style={{ color: '#bc0910' }}
                                onClick={() => {
                                    if (status.status_abertura) {
                                        showSnack('Este status é de abertura, não pode ser exluído, selecione outro status para ser o de abertura', enqueueSnackbar, 'warning');
                                        return;
                                    }

                                    if (status.status_final) {
                                        showSnack('Este status é de fechamento, não pode ser exluído, selecione outro status para ser o de fechamento', enqueueSnackbar, 'warning');
                                        return;
                                    }

                                    setIdStatus(status.id);
                                    setOpenDialogDeletar(true);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>
                    )
                }
            });

            setListStatus(getAllStatusFormated);
        })
        .catch(console.log);
    }, [reload]);

    return (
        <>
            <Container>
                <Grid container className="area-title">
                    <Grid item xs={12} className="grid-title">
                        <Typography className="title">Listagem de Status de Indicação</Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button
                            className="btnPrimary"
                            startIcon={<Add />}
                            onClick={() => setOpenDialogCad(true)}
                        >
                            Novo
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable
                            value={listStatus}
                            paginator
                            rows={10}
                            responsive
                            emptyMessage="Nenhum registro cadastrado..."
                            style={{ textAlign: 'center' }}
                            rowHover
                            currentPageReportTemplate="Mostrando {first} ao {last} de {totalRecords} registros"
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
                            rowsPerPageOptions={[10,25,50]}
                        >
                            <Column
                                field="titulo"
                                header="Nome"
                            />
                            <Column
                                field="cor"
                                header="cor"
                            />
                            <Column
                                field="status_abertura"
                                header="Status de Abertura"
                            />
                            <Column
                                field="status_final"
                                header="Status de Fechamento"
                            />
                            <Column
                                field="necessario_obs"
                                header="Necessário Observações"
                            />
                            <Column
                                field="btnAcoes"
                                header="Ações"
                            />
                        </DataTable>
                    </Grid>
                </Grid>
            </Container>

            <DialogCriar
                open={openDialogCad}
                reload={reload}
                setOpen={setOpenDialogCad}
                setReload={setReload}
            />

            <DialogDetalhes
                open={openDialogDetalhes}
                idStatus={idStatus}
                setOpen={setOpenDialogDetalhes}
                setIdStatus={setIdStatus}
            />

            <DialogEditar
                open={openDialogEditar}
                idStatus={idStatus}
                reload={reload}
                setOpen={setOpenDialogEditar}
                setReload={setReload}
                setIdStatus={setIdStatus}
            />

            <DialogDeletar
                open={openDialogDeletar}
                idStatus={idStatus}
                reload={reload}
                setOpen={setOpenDialogDeletar}
                setReload={setReload}
                setIdStatus={setIdStatus}
            />
        </>
    )
}