import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/data';

import { getCnpj, getCep } from '../../../utils/consulta';

import DialogLoading from '../../../components/DialogLoading';
import TextHelper from '../../../components/TextHelper';
import DialogInformaCadastroUsuario from '../components/DialogInformaCadastroUsuario';

import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask';

export default function ClientesForm({
  isIndication = false,
  isAtendiment = false,
  reload = false,
  setOpen = () => {},
  setReload = () => {},
  setIdCliente = () => {},
  setNomeCliente = () => {},
}) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));
  const [dateCad, setDateCad] = useState('');

  const [openLoading, setOpenLoading] = useState(false);
  const [openPerguntaCadUsuario, setOpenPerguntaCadUsuario] = useState(false);
  const [infoUsuarioCad, setInfoUsuarioCad] = useState('');

  const [tipo, setTipo] = useState('F');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [cliente, setCliente] = useState(false);
  const [fornecedor, setFornecedor] = useState(false);
  const [funcionario, setFuncionario] = useState(false);
  const [parceiro, setParceiro] = useState(false);
  const [nome, setNome] = useState('');
  const [apelido, setApelido] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [complemento, setComplemento] = useState('');
  const [contrato, setContrato] = useState('avulso');
  const [status, setStatus] = useState(1);
  const [obs, setObs] = useState('');

  const handleCnpj = (event, code = 'down') => (
    getCnpj(
      event,
      code,
      setOpenLoading,
      setNome,
      setApelido,
      setTelefone,
      setCelular,
      setEmail,
      setCep,
      setRua,
      setNumero,
      setBairro,
      setCidade,
      setEstado,
      setComplemento,
      cpfCnpj,
      ShowSnack,
      enqueueSnackbar
    )
  );

  const handleCep = (event, code = 'down') => {
    getCep(
      event,
      code,
      setRua,
      setBairro,
      setCidade,
      setEstado,
      setOpenLoading,
      ShowSnack,
      enqueueSnackbar,
      cep
    );
  }

  const salvar = () => {
    if (
      tipo &&
      nome &&
      rua &&
      numero &&
      bairro &&
      cidade &&
      estado
    ) {
      if (
        !isIndication &&
        !(
          cliente ||
          funcionario ||
          fornecedor ||
          parceiro
        )
      ) {
        ShowSnack(
          'Por favor, preencha todos campos!',
          enqueueSnackbar,
          'warning'
        );
        return;
      }

      if (
        !email &&
        (parceiro ||
        funcionario)
      ) {
        ShowSnack(
          'Por favor, preencha o campo de e-mail!',
          enqueueSnackbar,
          'warning'
        );
        return;
      }

      api
        .post(
          '/api/acesso/cadastrar/pessoa',
          {
            type_person:  tipo,
            contract:     isIndication ? 'avulso' : contrato,
            register:     cpfCnpj,
            name:         nome,
            surname:      apelido,
            street:       rua,
            number:       numero,
            district:     bairro,
            complement:   complemento,
            zip_code:     cep,
            city:         cidade,
            wow:          estado,
            phone:        telefone,
            cell:         celular,
            mail:         email,
            status:       isIndication ? true : status,
            note:         obs,
            is_client:    isIndication ? true : cliente,
            is_employees: isIndication ? false :funcionario,
            is_providers: isIndication ? false :fornecedor,
            is_partner:   isIndication ? false :parceiro
          }
        )
        .then(({ data }) => {
          if (data?.status === 400) {
            ShowSnack(data.message, enqueueSnackbar, 'warning');
            return;
          }

          if (isIndication && data.id) {
            api.post(
              'api/acesso/indicacoes/cadastrar',
              {
                user: sessao.user_block,
                cliente: data.id
              }
            ).then(({ data }) => {
              if (data.success) {
                setOpen(false);
                ShowSnack(data.message, enqueueSnackbar);
              } else {
                ShowSnack(data.message, enqueueSnackbar, 'error');
              }
            }).catch(console.log);
            return;
          }

          if (isAtendiment) {
            setOpen(false);
            setReload(!reload);
            setIdCliente(data.id);
            setNomeCliente(data.name);
          }

          ShowSnack(data.message, enqueueSnackbar);

          if (!isIndication && !isAtendiment) {
            if ((parceiro || funcionario) && email && permissoes.permi.cad_usuarios) {
              setInfoUsuarioCad(window.btoa(JSON.stringify({
                id: data.id,
                email,
                isParceiro: parceiro
              })));
              setOpenPerguntaCadUsuario(true);
            } else {
              history.push('/app/pessoas-list');
            }
          } else {
            
          }
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  const handleVerifyTypePerson = () => {
    if (cpfCnpj.length >= 18) {
      setTipo('J');
    } else {
      setTipo('F');
    }
  }

  useEffect(() => {
    if (!permissoes.permi.cad_pessoas) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    var options = {
      onKeyPress: function(cpf, ev, el, op) {
        var masks = ['000.000.000-000', '00.000.000/0000-00'];
        $('#cpfOuCnpj').mask(cpf.length > 14 ? masks[1] : masks[0], op);
      }
    };

    // CNPJ ou CPF
    $('#cpfOuCnpj').length > 11
      ? $('#cpfOuCnpj').mask('00.000.000/0000-00', options)
      : $('#cpfOuCnpj').mask('000.000.000-00#', options);

    // Celular
    $('#cel').mask('(00) 0.0000-0000');
    // Telefone
    $('#tel').mask('(00) 0000-0000');
    // CEP
    $('#cep').mask('00000-000');
  }, []);

  useEffect(() => {
    let cad = new Date().toISOString().split('T')[0].split('-');
    cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

    setDateCad(cad);
  }, []);

  return (
    <>
      {!isAtendiment && (
        <Container className="area-title">
          <Grid container>
            <Grid item xs={12} className="grid-title">
              <Typography className="title">
                Formulário de cadastro de pessoa
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}

      <Container className="area-components">
        <Grid container>
          <Grid item xs={12} md={isIndication ? 2 : 6} className="grid-campos">
            <TextField
              fullWidth
              variant="outlined"
              value={dateCad}
              disabled
              label="Data de Cadastrado"
            />
          </Grid>

          <Grid item xs={12} md={isIndication ? 4 : 6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo de pessoa:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Tipo de pessoa:"
                value={tipo}
                onChange={e => {
                  setTipo(e.target.value);
                }}
              >
                <MenuItem value="F">Física</MenuItem>
                <MenuItem value="J">Jurídica</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="cpfOuCnpj"
              label="CPF/CNPJ"
              variant="outlined"
              value={cpfCnpj}
              onChange={e => {
                setCpfCnpj(e.target.value);
              }}
              onKeyDown={handleCnpj}
              onBlur={handleVerifyTypePerson}
            />
            <TextHelper />
          </Grid>

          {
            !isIndication && (
              <Grid item xs={12} md={6} className="grid-campos">
                <FormGroup
                  row
                  style={{ border: '1px solid #bbb', padding: 5, borderRadius: 4 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cliente}
                        onChange={() => {
                          setCliente(!cliente);
                        }}
                      />
                    }
                    label="Cliente"
                  />
                  
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fornecedor}
                        onChange={() => {
                          setFornecedor(!fornecedor);
                        }}
                      />
                    }
                    label="Fornecedor"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={funcionario}
                        onChange={() => {
                          setFuncionario(!funcionario);
                        }}
                      />
                    }
                    label="Funcionário"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={parceiro}
                        onChange={() => {
                          setParceiro(!parceiro);
                        }}
                      />
                    }
                    label="Parceiro"
                  />
                </FormGroup>
              </Grid>
            )
          }

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nome/Razão"
              variant="outlined"
              required
              value={nome}
              onChange={e => {
                setNome(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Apelido/Fantasia"
              variant="outlined"
              value={apelido}
              onChange={e => {
                setApelido(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="tel"
              label="Telefone"
              variant="outlined"
              value={telefone}
              onChange={e => {
                setTelefone(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              type="text"
              id="cel"
              label="Celular"
              variant="outlined"
              value={celular}
              onChange={e => {
                setCelular(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              type="email"
              fullWidth
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              required={parceiro || funcionario}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="cep"
              label="Cep"
              variant="outlined"
              value={cep}
              onChange={e => {
                setCep(e.target.value);
              }}
              onKeyDown={handleCep}
            />
            <TextHelper />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Rua"
              variant="outlined"
              value={rua}
              onChange={e => {
                setRua(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Número"
              variant="outlined"
              value={numero}
              onChange={e => {
                const value = (e.target.value).match(/\d+/);

                if (Array.isArray(value)) {
                  setNumero(value[0]);
                } else if (e.target.value.length === 0) {
                  setNumero('');
                }
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Bairro"
              variant="outlined"
              value={bairro}
              onChange={e => {
                setBairro(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Cidade"
              variant="outlined"
              value={cidade}
              onChange={e => {
                setCidade(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="UF"
              variant="outlined"
              value={estado}
              onChange={e => {
                setEstado(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Complemento"
              variant="outlined"
              value={complemento}
              onChange={e => {
                setComplemento(e.target.value);
              }}
            />
          </Grid>
          
          {
            !isIndication && (
              <>
                <Grid item xs={12} md={6} className="grid-campos">
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Prestamento de serviço:
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Prestamento de serviço:"
                      value={contrato}
                      onChange={e => {
                        setContrato(e.target.value);
                      }}
                    >
                      <MenuItem value="avulso">Avulso</MenuItem>
                      <MenuItem value="contratual">Contratual</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} className="grid-campos">
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status:
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Status:"
                      value={status}
                      onChange={e => {
                        setStatus(!status);
                      }}
                    >
                      <MenuItem value={1}>Ativo</MenuItem>
                      <MenuItem value={0}>Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </> 
            )
          }

          <Grid item xs={12} className="grid-campos">
            <FormLabel style={{ color: '#08318e', fontWeight: 'bold' }}>
              Observações:
            </FormLabel>
            <br />
            <TextareaAutosize
              style={{
                width: '100%',
                resize: 'none',
                padding: 10,
                fontSize: 16
              }}
              value={obs}
              onChange={e => {
                setObs(e.target.value);
              }}
              className="textareaStyle"
              placeholder="Digite as observações sobre este cliente aqui..."
              rowsMin={5}
              rowsMax={5}
            />
          </Grid>

          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Button className="button-register" onClick={() => salvar()}>
              Cadastrar
            </Button>
          </Grid>
        </Grid>
      </Container>

      <DialogLoading open={openLoading} />

      <DialogInformaCadastroUsuario
        open={openPerguntaCadUsuario}
        setOpen={setOpenPerguntaCadUsuario}
        infoUsuario={infoUsuarioCad}
      />
    </>
  );
}
