export function getTimeSession(dateCreated) {
  const now = new Date().getTime();
  const created = dateCreated;

  const milissegundos = created - now;
  const time = milissegundos / 1000;

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;
  let secondsFormat = String(seconds).split('.')[0];
  secondsFormat = secondsFormat.padStart(2, '0');
  const minutesFormat = String(minutes).padStart(2, '0');
  const hoursFormat = String(hours).padStart(2, '0');


  return {
    timeFormat: `${hoursFormat}:${minutesFormat}:${secondsFormat}`,
    reloadSession: milissegundos <= 600000 && milissegundos >= 0,
  };
}
