export function getTimeSuport(dateCreated) {
  const now = new Date().getTime();
  const created = new Date(dateCreated).getTime();

  const milissegundos = now - created;
  const time = milissegundos / 1000;

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;
  let secondsFormat = String(seconds).split('.')[0];
  secondsFormat = secondsFormat.padStart(2, '0');
  const minutesFormat = String(minutes).padStart(2, '0');
  const hoursFormat = String(hours).padStart(2, '0');

  const backgroundColorTime =
    time <= 1800
      ? '#21c775'
      : time > 1800 && time <= 3600
      ? '#cab518'
      : '#bd0f0f';

  return {
    color: backgroundColorTime,
    timeFormat: `${hoursFormat}:${minutesFormat}:${secondsFormat}`
  };
}
