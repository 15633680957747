import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';

import api from 'src/services/data';

import './styles.css';

export default function DialogEditar({
    open,
    idStatus,
    reload,
    setOpen,
    setIdStatus,
    setReload
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [dataFormStatus, setDataFormStatus] = useState({
        titulo: '',
        cor: '',
        status_abertura: false,
        status_final: false,
        necessario_obs: false,
    });


    const onChangeDataForm = (position, value) => {
        setDataFormStatus(oldValues => ({ ...oldValues, [position]: value }));
    }

    const onClosed = () => {
        setOpen(false);
        setIdStatus('');
        setDataFormStatus({
            titulo: '',
            cor: '',
            status_abertura: false,
            status_final: false,
            necessario_obs: false,
        });
    }

    const onSave = () => {
        if (!dataFormStatus.titulo) {
            showSnack('O campo de título é obrigatório', enqueueSnackbar, 'warning');
            return;
        }

        if (!dataFormStatus.cor) {
            showSnack('O campo de cor é obrigatório', enqueueSnackbar, 'warning');
            return;
        }

        api.put(`api/acesso/status-indicacao/${idStatus}`, dataFormStatus)
        .then(({ data }) => {
            showSnack(data.message, enqueueSnackbar);
            setReload(!reload);
            onClosed();
        })
        .catch(console.log)
    }

    useEffect(() => {
        if (open && idStatus) {
            api.get(`api/acesso/status-indicacao/${idStatus}`)
            .then(({ data }) => {
                setDataFormStatus(data);
            })
            .catch(console.log)
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle className="dialog_title_detalhes_status">
                Detalhes
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Nome:"
                            value={dataFormStatus.titulo}
                            onChange={(e) => onChangeDataForm('titulo', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Cor:"
                            required
                            type="color"
                            value={dataFormStatus.cor}
                            onChange={(e) => onChangeDataForm('cor', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Status de Abertura?</InputLabel>

                            <Select
                                label="Status de Abertura?"
                                value={dataFormStatus.status_abertura}
                                onChange={(e) => onChangeDataForm('status_abertura', e.target.value)}
                            >
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Status de Fechamento?</InputLabel>

                            <Select
                                label="Status de Fechamento?"
                                value={dataFormStatus.status_final}
                                onChange={(e) => onChangeDataForm('status_final', e.target.value)}
                            >
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Necessário Observação?</InputLabel>

                            <Select
                                label="Necessário Observação?"
                                value={dataFormStatus.necessario_obs}
                                onChange={(e) => onChangeDataForm('necessario_obs', e.target.value)}
                            >
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}