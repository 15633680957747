import axios from 'axios';

const AMB_DEV = 'PRODUCTION'; // DEV OR PRODUCTION

const api = axios.create({
  baseURL: AMB_DEV === 'DEV' ?
  'http://127.0.0.1:8000' :
  'https://apimm.develop.systemsautomacao.com.br/public/'
  // 'https://maximummanagerapiweb.systemsautomacao.com.br/public/'
});

/**
 * Bearer Authorization Header
 */
 api.interceptors.request.use((config) => {
  let data = sessionStorage.getItem('@Account#Sessio#MM@');

  if (data) {
    data = JSON.parse(data);
  }

  if (config.headers && !!data) {
    if (!!data) {
      config.headers["Authorization"] = `Bearer ${data.lock}`;
    } else {
      config.headers["Authorization"] = "";
    }
  }

  return config;
});

export default api;
