import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Info from '@material-ui/icons/Info';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';

import DialogFullScreenAtendimentos from './Components/DialogFullScreenAtendimentos';
import DialogDetailsV1 from './Components/DetalhesV1';

import api from '../../../services/data';

import './styles.css';

function AcompanharAtendimentosV1() {
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));
  const { enqueueSnackbar } = useSnackbar();
  const [atendimentos, setAtendimentos] = useState([]);

  const [openFullScreen, setOpenFullScreen] = useState(false);

  const [openDetails, setOpenDetails] = useState(false);
  const [idAtendimento, setIdAtendimento] = useState([]);

  useEffect(() => {
    if (permissoes.permi.acompanhar_atendimentos) {
      api
        .get(`api/acesso/lista/atendimentos/v1`)
        .then(({ data }) => {
          const getAtendimentos = data.reverse().map(atendimento => {
            const status = (
              <FormLabel
                style={{
                  background:
                    atendimento.status === 'aberto'
                      ? '#007ad9'
                      : atendimento.status === 'pausado'
                      ? '#d95c00'
                      : '#24b324',
                  color: '#fff',
                  padding: '5px 10px',
                  borderRadius: 2,
                  fontWeight: 700
                }}
              >
                {atendimento.status}
              </FormLabel>
            );

            return {
              ...atendimento,
              dataFormat: moment(atendimento.created_at).format('DD/MM/YYYY HH:mm'),
              statusFormat: status,
              getSolicitante: atendimento.solicitante
                ? atendimento.solicitante
                : '-',
              btnAcoes: (
                <>
                  <IconButton
                    onClick={() => {
                      setOpenDetails(true);
                      setIdAtendimento(atendimento.id);
                    }}
                  >
                    <Info style={{ color: '#6420cfd9' }} />
                  </IconButton>
                </>
              )
            };
          });

          setAtendimentos(getAtendimentos);
        })
        .catch(() => {
          ShowSnack(
            '[Erro]: Houve um erro com o servidor. Informe ao suporte!',
            enqueueSnackbar,
            'error'
          );
        });
    }
  }, []);

  return (
    <Grid container>
      {permissoes.permi.acompanhar_atendimentos ? (
        <>
          <Grid
            item
            xs={12}
            className="area_top_table"
          >
            <Box>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ZoomOutMap />}
                onClick={() => {
                  setOpenFullScreen(true);
                }}
              >
                Expandir
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} className="paginator_acompanhar_atendimentos">
            <DataTable
              paginator
              rows={10}
              responsive
              emptyMessage="Nenhum registro cadastrado..."
              style={{ textAlign: 'center' }}
              value={atendimentos}
            >
              <Column
                field="protocolo"
                header="Nº de Protocolo"
                filter
                filterMatchMode="contains"
              />
              <Column
                field="cliente"
                header="Cliente"
                filter
                filterMatchMode="contains"
              />
              <Column field="statusFormat" header="Status" />
              <Column field="btnAcoes" header="Ações" />
            </DataTable>
          </Grid>
        </>
      ) : (
        <>
          <Typography
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              marginTop: 10,
              fontWeight: 'bold',
              padding: 10,
              width: '100%',
              background: '#d09d0f',
              color: '#222',
              borderRadius: 4,
              fontSize: 20
            }}
          >
            Sem permissão para VISUALIZAÇÃO
          </Typography>
        </>
      )}

      <DialogDetailsV1
        open={openDetails}
        idAtendimento={idAtendimento}
        setOpen={setOpenDetails}
        setIdAtendimento={setIdAtendimento}
      />

      <DialogFullScreenAtendimentos
        open={openFullScreen}
        setOpen={setOpenFullScreen}
        atendimentos={atendimentos}
      />
    </Grid>
  );
}

export default AcompanharAtendimentosV1;
