/**
 * Implementação da requisição na web
 */

 function jsonp(url, timeout) {
  // Gera um nome aleatório para a função de callback
  const func = 'jsonp_' + Math.random().toString(36).substr(2, 5);

  return new Promise(function(resolve, reject) {
      // Cria um script
      let script = document.createElement('script');

      // Cria um timer para controlar o tempo limite
      let timer = setTimeout(() => {
          reject('Tempo limite atingido');
          document.body.removeChild(script);
      }, timeout);

      // Cria a função de callback
      window[func] = (json) => {
          clearTimeout(timer);
          resolve(json);
          document.body.removeChild(script);
          delete window[func];
      };

      // Adiciona o script na página para inicializar a solicitação
      script.src = url + '?callback=' + encodeURI(func);
      document.body.appendChild(script);
  });
}

/**
 * Consulta um CNPJ
 */
function consultaCNPJ(cnpj) {
    // Limpa o CNPJ para conter somente numeros, removendo traços e pontos
    cnpj = cnpj.replace(/\D/g, '');

    // Consulta o CNPJ na ReceitaWS com 60 segundos de tempo limite
    return jsonp('https://www.receitaws.com.br/v1/cnpj/' + encodeURI(cnpj), 60000)
        .then((json) => {
            if (json['status'] === 'ERROR') {
                return Promise.reject(json['message']);
            } else {
                return Promise.resolve(json);
            }
        });
}

/**
 * Consulta um CEP
 */
function consultaCEP(cep) {
    // Limpa o CEP para conter somente numeros, removendo traços e pontos
    cep = cep.replace(/\D/g, '');

    // Como a API retorna 404 com CEPs com tamanhos inválidos
    // Iremos validar antes para não ter que esperar o tempo limite do JSONP
    if (cep.length !== 8) return Promise.reject('CEP inválido');

    // Consulta o CEP na ViaCEP com 30 segundos de tempo limite
    return jsonp('https://viacep.com.br/ws/' + encodeURI(cep) + '/json/', 30000)
        .then((json) => {
            if (json['erro'] === true) {
                return Promise.reject('CEP não encontrado');
            } else {
                return Promise.resolve(json);
            }
        });
}

export function getCnpj(
  event,
  code,
  setOpenLoading,
  setNome,
  setApelido,
  setTelefone,
  setCelular,
  setEmail,
  setCep,
  setRua,
  setNumero,
  setBairro,
  setCidade,
  setEstado,
  setComplemento,
  cpfCnpj,
  ShowSnack,
  enqueueSnackbar
) {
  if (event.key === 'Enter' || code === 'blur') {
    if (cpfCnpj.split('').length === 18) {
      setOpenLoading(true);
      consultaCNPJ(cpfCnpj).then((json) => {
        setNome(json.nome);
        setApelido(json.fantasia);
        setTelefone((json.telefone).split('/ ')?.[0]);
        setCelular((json.telefone).split('/ ')?.[1]);
        setEmail(json.email);
        setCep(json.cep);
        setRua(json.logradouro);
        setNumero(json.numero);
        setBairro(json.bairro);
        setCidade(json.municipio);
        setEstado(json.uf);
        setComplemento(json.complemento);
        setOpenLoading(false);
      }, (erro) => {
          ShowSnack(`ERRO: ${erro}`, enqueueSnackbar, 'warning');
          setOpenLoading(false);
      });
    }
  }
}

export function getCep(
  event,
  code,
  setRua,
  setBairro,
  setCidade,
  setEstado,
  setOpenLoading,
  ShowSnack,
  enqueueSnackbar,
  cep
) {
  if (event.key === 'Enter' || code === 'blur') {
    if (cep.split('').length === 9) {
      setOpenLoading(true);
      consultaCEP(cep).then((json) => {
        setRua(json.logradouro);
        setBairro(json.bairro);
        setCidade(json.localidade);
        setEstado(json.uf);
        setOpenLoading(false);
      }, (erro) => {
          ShowSnack(`ERRO: ${erro}`, enqueueSnackbar, 'warning');
          setOpenLoading(false);
      }); 
    }
  }
}
