import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    background: '#013494',
    marginTop: 10,
    cursor: 'pointer'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const Total = ({ valor, className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      onClick={() => {
        history.push('/app/atendimentos/total');
      }}
    >
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ color: '#f2f4f5' }} gutterBottom variant="h6">
              Total de Atendimentos
            </Typography>
            <Typography style={{ color: '#f2f4f5' }} variant="h1">
              {valor}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Total.propTypes = {
  className: PropTypes.string
};

export default Total;
