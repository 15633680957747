import React from 'react';
import { useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import './styles.css';

export default function DialogInformaCadastroUsuario({
    open,
    infoUsuario,
    setOpen
}) {
    const history = useHistory();

    const onClosed = () => {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className='dialog_title_confirm_cad_user'>
                Informação
            </DialogTitle>

            <DialogContent>
                <Typography>
                    Percebemos que você está cadastrando um parceiro,
                    deseja já cadastraro usuário para este cadastro?
                </Typography>
            </DialogContent>

            <DialogActions>
            <Button
                variant="contained"
                onClick={() => history.push('/app/pessoas-list')}
            >
                Agora Não
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/app/usuarios-form/${infoUsuario}`)}
            >
                Agora Sim
            </Button>
            </DialogActions>
        </Dialog>
    )
}