import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import './styles.css';

export default function MessageEmpty({ textEmpty }) {
    return (
        <Box className="area_empty">
            <Typography className="text_empty">
                { textEmpty ?? 'Nenhum item encontrado...' }
            </Typography>
        </Box>
    )
}