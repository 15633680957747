import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';

import api from 'src/services/data';

import './styles.css';

export default function DialogDeletar({
    open,
    reload,
    idStatus,
    setOpen,
    setReload,
    setIdStatus
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [podeExcluir, setPodeExcluir] = useState(false);

    const onClosed = () => {
        setOpen(false);
        setIdStatus('');
        setPodeExcluir(false);
    }

    const onDelete = () => {
        if (idStatus) {
            showSnack('Informe a identificação do status.', enqueueSnackbar, 'warning');
            return;
        }

        if (!podeExcluir) {
            showSnack('Não pode ser excluído, está com movimentações.', enqueueSnackbar, 'warning');
            return;
        }

        api.delete(`api/acesso/status-indicacao/${idStatus}`)
        .then(() => {
            showSnack('Status excluído com sucesso.', enqueueSnackbar);
            onClosed();
            setReload(!reload);
        })
        .catch(console.log)
    }

    useEffect(() => {
        if (open && idStatus) {
            api.get(`api/acesso/verifica-status-movimentacao/${idStatus}`)
            .then(({ data }) => {
                if (data.length <= 0) {
                    setPodeExcluir(true);
                }
            })
            .catch(console.log)
        }
    }, [open, idStatus])

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className="dialog_title_del_status">
                Deletar Status de Indicação
            </DialogTitle>

            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {
                            podeExcluir ? (
                                <Alert severity="warning">
                                    <AlertTitle>Importante</AlertTitle>

                                    Ao confirmar a exclusão este status será deletado permanentemente.
                                </Alert>
                            ) : (
                                <Alert severity="error">
                                    <AlertTitle>Não é possível excluir este status</AlertTitle>

                                    Foi identificado que este status está com movimentações pendentes,
                                    por este motivo o mesmo não pode ser excluído, para continuar com esta
                                    ação é necessário concluir as movimentações.
                                </Alert>
                            )
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            
            {
                podeExcluir && (
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onDelete}
                        >Excluir</Button>
                    </DialogActions>
                )
            }
        </Dialog>
    )
}