import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../utils/snacks';

import MeusAtendimentos from './MeusAtendimentos';
import Finalizados from './Finalizados';
import AcompanharAtendimentos from './AcompanharAtendimentos';
import AcompanharAtendimentosV1 from './AcompanharAtendimentosV1';

export default function Atendimentos() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));
  const [value, setValue] = useState(0);
  const { status } = useParams();

  useEffect(() => {
    if (
      permissoes.permi.novo_atendimentos != 1 &&
      permissoes.permi.pausar_atendimentos != 1 &&
      permissoes.permi.continuar_atendimentos != 1 &&
      permissoes.permi.finalizar_atendimentos != 1 &&
      permissoes.permi.ematendimento_atendimentos != 1 &&
      permissoes.permi.finalizados_atendimentos != 1 &&
      permissoes.permi.acompanhar_atendimentos != 1
    ) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    if (status) {
      setValue(2);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <Paper style={{ background: '#cecece' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label="Em Atendimento"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
            />
            <Tab
              label="Finalizados"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
            />
            <Tab
              label="Acompanhar Atendimentos"
              id="simple-tab-2"
              aria-controls="simple-tabpanel-2"
            />
            <Tab
              label="Atendimentos V1"
              id="simple-tab-3"
              aria-controls="simple-tabpanel-3"
            />
          </Tabs>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <MeusAtendimentos />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Finalizados />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <AcompanharAtendimentos status={status === 'total' ? '' : status} />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <AcompanharAtendimentosV1 />
        </TabPanel>
      </Grid>
    </Container>
  );
}
