import React from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Close from '@material-ui/icons/Close';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function DialogFullScreenAtendimentos({ open, setOpen, atendimentos }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullScreen
    >
      <DialogTitle
        style={{
          background: '#013494'
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap: 10
          }}
        >
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close
              style={{ color: '#fff', fontWeight: 'bold', fontSize: '2rem' }}
            />
          </IconButton>
          <Typography
            style={{ color: '#fff', fontWeight: 'bold', fontSize: 20 }}
          >
            Acompanhar Atendimentos
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DataTable
          paginator
          rows={10}
          responsive
          emptyMessage="Nenhum registro cadastrado..."
          style={{ textAlign: 'center' }}
          value={atendimentos}
          resizableColumns
        >
          <Column
            field="protocolo"
            header="Nº de Protocolo"
            filter
            filterMatchMode="contains"
            style={{ width: '10%' }}
          />
          <Column
            field="pessoa.nome"
            header="Cliente"
            filter
            filterMatchMode="contains"
          />
          <Column
            field="getSolicitante"
            header="Solicitante"
            filter
            filterMatchMode="contains"
          />
          <Column
            field="usuario.atendente"
            header="Atendente"
            filter
            filterMatchMode="contains"
          />
          <Column field="statusFormat" header="Status" />
          <Column field="dataFormat" header="Data/Hora" filter filterMatchMode="contains" style={{ width: '10%' }} />
          <Column field="btnAcoes" style={{ width: '6%' }} />
        </DataTable>
      </DialogContent>
    </Dialog>
  );
}

export default DialogFullScreenAtendimentos;
