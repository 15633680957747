import React from 'react';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import './styles/global.css';
import { SnackbarProvider } from 'notistack';
import './utils/masks';

import 'moment/locale/pt-br';

import Routes from './routes';

const App = () => {
  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
