import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormLabel from '@material-ui/core/FormLabel';

import { getTimeSuportFinished } from 'src/utils/getTimeSuportFinished';

import PropTypes from 'prop-types';

import Informacoes from './components/Informacoes';
import Historico from './components/Historico';

import api from 'src/services/data';

const useStyles = makeStyles(() => ({
  areaTitle: {
    background: '#08318e'
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 25,
    textAlign: 'center'
  },
}));

function DetailsV1({
  open,
  idAtendimento,
  setIdAtendimento,
  setOpen,
}) {
    const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const [detalhes, setDetalhes] = React.useState({});
    const [timeFinished, setTimeFinished] = React.useState({});

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
            )}
        </Box>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onClosed = () => {
        setIdAtendimento('');
        setOpen(false);
        setValue(0);
    }

    React.useEffect(() => {
      if (open) {
        api.get(`api/acesso/busca/atendimento/v1/${idAtendimento}`)
        .then(({data}) => {
            setDetalhes(data.map(atendimento => {
                const status = (
                    <FormLabel
                      style={{
                        background:
                          atendimento.status === 'aberto'
                            ? '#007ad9'
                            : atendimento.status === 'pausado'
                            ? '#d95c00'
                            : '#24b324',
                        color: '#fff',
                        padding: '5px 10px',
                        borderRadius: 2,
                        fontWeight: 700
                      }}
                    >
                      {atendimento.status}
                    </FormLabel>
                );

                return {
                    ...atendimento,
                    statusFormat: status,
                }
            })[0]);

            setTimeFinished(getTimeSuportFinished(data[0]?.inicio, data[0]?.fim));
        }).catch(console.log)
      }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className={classes.areaTitle}>
                <Typography className={classes.title}>+ Informações</Typography>
            </DialogTitle>

            <Grid container>
                <Grid item xs={12}>
                    <Paper style={{ background: '#cecece' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="secondary"
                            centered
                        >
                            <Tab
                                label="Detalhes"
                                id="simple-tab-0"
                                aria-controls="simple-tabpanel-0"
                            />

                            <Tab
                                label="Histórico"
                                id="simple-tab-1"
                                aria-controls="simple-tabpanel-1"
                            />
                        </Tabs>
                    </Paper>
                </Grid>
            </Grid>

            <DialogContent>
                <TabPanel value={value} index={0}>
                  <Informacoes
                    detalhes={detalhes}
                    timeFinished={timeFinished}
                  />
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Historico detalhes={detalhes}/>
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
}

export default DetailsV1;
