import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import FilterList from '@material-ui/icons/FilterList';
import Info from '@material-ui/icons/Info';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import { getTimeSuport } from '../../../utils/getTimeSuport';

import Details from '../Components/Details';
import DialogFullScreenAtendimentos from './Components/DialogFullScreenAtendimentos';

import api from '../../../services/data';

import './styles.css';

function AcompanharAtendimentos({ status }) {
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));
  const { enqueueSnackbar } = useSnackbar();
  const [atendimentos, setAtendimentos] = useState([]);
  const [atendimentosFiltro, setAtendimentosFiltro] = useState([]);

  const [statusFilter, setStatusFilter] = useState(status);

  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [detalhesAtendimento, setDetalhesAtendimento] = useState([]);

  const [time, setTime] = useState({
    color: '#007ad9',
    timeFormat: '00:00:00'
  });
  const [createdAt, setCreatedAt] = useState('');

  const filterStatus = e => {
    setStatusFilter(e.target.value);

    if (e.target.value === 'todos') {
      setAtendimentosFiltro(atendimentos);
    } else {
      setAtendimentosFiltro(
        atendimentos.filter(filtro => {
          return filtro.status === e.target.value;
        })
      );
    }
  };

  useEffect(() => {
    if (permissoes.permi.acompanhar_atendimentos) {
      api
        .get(`api/acesso/lista/atendimentos`)
        .then(({ data }) => {
          const getAtendimentos = data.reverse().map(atendimento => {
            const status = (
              <FormLabel
                style={{
                  background:
                    atendimento.status === 'aberto'
                      ? '#007ad9'
                      : atendimento.status === 'pausado'
                      ? '#d95c00'
                      : '#24b324',
                  color: '#fff',
                  padding: '5px 10px',
                  borderRadius: 2,
                  fontWeight: 700
                }}
              >
                {atendimento.status}
              </FormLabel>
            );

            return {
              ...atendimento,
              dataFormat: moment(atendimento.created_at).format('DD/MM/YYYY HH:mm'),
              statusFormat: status,
              getSolicitante: atendimento.solicitante
                ? atendimento.solicitante
                : '-',
              btnAcoes: (
                <>
                  <IconButton
                    onClick={() => {
                      if (!atendimento.fim) {
                        setCreatedAt(atendimento?.inicio);
                      }
                      setOpenDetails(true);
                      setDetalhesAtendimento({
                        ...atendimento,
                        statusFormat: status
                      });
                    }}
                  >
                    <Info style={{ color: '#6420cfd9' }} />
                  </IconButton>
                </>
              )
            };
          });

          setAtendimentos(getAtendimentos);
          setAtendimentosFiltro(
            status
              ? getAtendimentos.filter(dados => {
                  return dados.status === status;
                })
              : getAtendimentos
          );
        })
        .catch(() => {
          ShowSnack(
            '[Erro]: Houve um erro com o servidor. Informe ao suporte!',
            enqueueSnackbar,
            'error'
          );
        });
    }
  }, []);

  useEffect(() => {
    if (createdAt) {
      setTimeout(() => {
        setTime(getTimeSuport(createdAt));
      }, 1000);
    }
  }, [createdAt, time]);

  return (
    <Grid container>
      {permissoes.permi.acompanhar_atendimentos ? (
        <>
          <Grid
            item
            xs={12}
            className="area_top_table"
          >
            <Box>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ZoomOutMap />}
                onClick={() => {
                  setOpenFullScreen(true);
                }}
              >
                Expandir
              </Button>
            </Box>

            <Box>
              <FormControl style={{ minWidth: 220 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                  }}
                >
                  <FilterList /> Filtrar:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={e => {
                    filterStatus(e);
                  }}
                  value={statusFilter}
                >
                  <MenuItem value="todos">Todos</MenuItem>
                  <MenuItem value="aberto">Aberto</MenuItem>
                  <MenuItem value="pausado">Pausado</MenuItem>
                  <MenuItem value="finalizado">Finalizado</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} className="paginator_acompanhar_atendimentos">
            <DataTable
              paginator
              rows={10}
              responsive
              emptyMessage="Nenhum registro cadastrado..."
              style={{ textAlign: 'center' }}
              value={atendimentosFiltro}
            >
              <Column
                field="protocolo"
                header="Nº de Protocolo"
                filter
                filterMatchMode="contains"
              />
              <Column
                field="pessoa.nome"
                header="Cliente"
                filter
                filterMatchMode="contains"
              />
              <Column field="statusFormat" header="Status" />
              <Column field="btnAcoes" header="Ações" />
            </DataTable>
          </Grid>
        </>
      ) : (
        <>
          <Typography
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              marginTop: 10,
              fontWeight: 'bold',
              padding: 10,
              width: '100%',
              background: '#d09d0f',
              color: '#222',
              borderRadius: 4,
              fontSize: 20
            }}
          >
            Sem permissão para VISUALIZAÇÃO
          </Typography>
        </>
      )}

      <Details
        open={openDetails}
        setOpen={setOpenDetails}
        time={detalhesAtendimento.fim ? '' : time}
        setTime={detalhesAtendimento.fim ? () => {} : setTime}
        setCreatedAt={detalhesAtendimento.fim ? () => {} : setCreatedAt}
        detalhes={detalhesAtendimento}
        setDetalhes={setDetalhesAtendimento}
      />

      <DialogFullScreenAtendimentos
        open={openFullScreen}
        setOpen={setOpenFullScreen}
        atendimentos={atendimentos}
      />
    </Grid>
  );
}

export default AcompanharAtendimentos;
