import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Close from '@material-ui/icons/Close';

import PessoasForm from '../../../Pessoas/PessoasForm';

function DialogCadCliente({ open, setOpen, recarregar, setRecarregar, setIdCliente, setNomeCliente }) {
    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            fullScreen
        > 
                <DialogTitle>
                    <AppBar position="fixed">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: '#fff' }}>
                                Cadastrar Cliente
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent style={{ background: '#888', padding: '40px 0px' }}>
                    <PessoasForm
                      isAtendiment={true}
                      reload={recarregar}
                      setOpen={setOpen}
                      setReload={setRecarregar}
                      setIdCliente={setIdCliente}
                      setNomeCliente={setNomeCliente}
                    />
                </DialogContent>
        </Dialog>
    );
}

export default DialogCadCliente;
