import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import NotFoundView from './views/errors/NotFoundView';

import DashboardView from './views/DashboardView';

// Iniciados //
import AtualizarDados from './views/AtualizarDados';

// Concluídos Parcialmente //
import LoginView from './views/Login';
import Atendimentos from './views/Atendimentos';
import PessoasList from './views/Pessoas/PessoasList';
import PessoasForm from './views/Pessoas/PessoasForm';
import PessoasFormEdit from './views/Pessoas/PessoasFormEdit';
import GrupopsList from './views/Grupops/GrupopsList';
import SubGrupopsList from './views/SubGrupops/SubGrupopsList';
import GrupoServicoList from './views/GrupoServico/GruposList';
import ProdutosList from './views/Produtos/ProdutosList';
import ProdutosForm from './views/Produtos/ProdutosForm';
import ProdutosFormEdit from './views/Produtos/ProdutosFormEdit';
import ServicosList from './views/Servicos/ServicosList';
import ServicosForm from './views/Servicos/ServicosForm';
import ServicosFormEdit from './views/Servicos/ServicosFormEdit';
import PerfisList from './views/Perfis/PerfisList';
import PerfisForm from './views/Perfis/PerfisForm';
import PerfisFormEdit from './views/Perfis/PerfisFormEdit';
import UsuariosList from './views/Usuarios/UsuariosList';
import UsuariosForm from './views/Usuarios/UsuariosForm';
import UsuariosFormEdit from './views/Usuarios/UsuariosFormEdit';

import RelatorioAtendimentos from './views/Relatorios/Atendimentos';
import RelatorioAtendimentosV1 from './views/Relatorios/AtendimentosV1';

import Indicacoes from './views/Indicacoes';
import StatusIndicacao from './views/StatusIndicacao';

export default function Routes() {
  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        <MainLayout {...props}>
          <Component />
        </MainLayout>
      )}
    />
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        <DashboardLayout {...props}>
          <Component />
        </DashboardLayout>
      )}
    />
  );

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/" component={LoginView} exact />
        <PublicRoute path="/login" component={LoginView} exact />

        <PrivateRoute path="/app" component={DashboardView} exact />
        <PrivateRoute path="/app/dashboard" component={DashboardView} exact />

        <PrivateRoute path="/app/pessoas-list" component={PessoasList} exact />
        <PrivateRoute path="/app/pessoas-form" component={PessoasForm} exact />
        <PrivateRoute
          path="/app/pessoas-form-edit/:id"
          component={PessoasFormEdit}
          exact
        />

        <PrivateRoute path="/app/grupops-list" component={GrupopsList} exact />
        <PrivateRoute
          path="/app/subgrupops-list"
          component={SubGrupopsList}
          exact
        />

        <PrivateRoute
          path="/app/produtos-list"
          component={ProdutosList}
          exact
        />
        <PrivateRoute
          path="/app/produtos-form"
          component={ProdutosForm}
          exact
        />
        <PrivateRoute
          path="/app/produtos-form-edit/:id"
          component={ProdutosFormEdit}
          exact
        />

        <PrivateRoute
          path="/app/gruposs-list"
          component={GrupoServicoList}
          exact
        />
        <PrivateRoute
          path="/app/servicos-list"
          component={ServicosList}
          exact
        />
        <PrivateRoute
          path="/app/servicos-form"
          component={ServicosForm}
          exact
        />
        <PrivateRoute
          path="/app/servicos-form-edit/:id"
          component={ServicosFormEdit}
          exact
        />

        <PrivateRoute path="/app/perfis-list" component={PerfisList} exact />
        <PrivateRoute path="/app/perfis-form" component={PerfisForm} exact />
        <PrivateRoute
          path="/app/perfis-form-edit/:id"
          component={PerfisFormEdit}
          exact
        />

        <PrivateRoute
          path="/app/usuarios-list"
          component={UsuariosList}
          exact
        />
        <PrivateRoute
          path="/app/usuarios-form"
          component={UsuariosForm}
          exact
        />
        <PrivateRoute
          path="/app/usuarios-form/:infoUsuario"
          component={UsuariosForm}
          exact
        />
        <PrivateRoute
          path="/app/usuarios-form-edit/:id"
          component={UsuariosFormEdit}
          exact
        />

        <PrivateRoute path="/app/atendimentos" component={Atendimentos} exact />
        <PrivateRoute
          path="/app/atendimentos/:status"
          component={Atendimentos}
          exact
        />

        <PrivateRoute
          path="/app/atualizar-dados"
          component={AtualizarDados}
          exact
        />

        <PrivateRoute
          path="/app/relatorio/atendimentos"
          component={RelatorioAtendimentos}
          exact
        />

        <PrivateRoute
          path="/app/relatorio/atendimentos-v1"
          component={RelatorioAtendimentosV1}
          exact
        />

        <PrivateRoute
          path="/app/indicacoes"
          component={Indicacoes}
          exact
        />

        <PrivateRoute
          path="/app/status-indicacao"
          component={StatusIndicacao}
          exact
        />

        <PublicRoute path="*" component={NotFoundView} />
      </Switch>
    </BrowserRouter>
  );
}
