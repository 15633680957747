import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';

export default function BoxPermissoes({
  title,
  valueVis,
  mudarValueVis,
  valueCad,
  mudarValueCad,
  valueEdi,
  mudarValueEdi,
  valueDel,
  mudarValueDel
}) {
  const handleChangeVis = () => {
    mudarValueVis(!valueVis);
  };

  const handleChangeCad = () => {
    mudarValueCad(!valueCad);
  };

  const handleChangeEdi = () => {
    mudarValueEdi(!valueEdi);
  };

  const handleChangeDel = () => {
    mudarValueDel(!valueDel);
  };

  return (
    <Grid item xs={12} md={3} style={{ padding: 4 }}>
      <Box
        style={{
          border: '1px solid #08318e',
          padding: 10,
          borderRadius: 4
        }}
      >
        <FormControl>
          <FormLabel>
            <Typography
              style={{
                color: '#08318e',
                fontSize: 16,
                fontWeight: 'bold'
              }}
            >
              {title}
            </Typography>
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={valueVis}
                  onChange={handleChangeVis}
                  name="Visualizar"
                />
              }
              label="Visualizar"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={valueCad}
                  onChange={handleChangeCad}
                  name="Cadastrar"
                />
              }
              label="Cadastrar"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={valueEdi}
                  onChange={handleChangeEdi}
                  name="Editar"
                />
              }
              label="Editar"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={valueDel}
                  onChange={handleChangeDel}
                  name="Deletar"
                />
              }
              label="Deletar"
            />
          </FormGroup>
        </FormControl>
      </Box>
    </Grid>
  );
}
