import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  CircularProgress,
  Grid
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Sales = ({
  titleGraf,
  type,
  funcionarios,
  dadosAtual,
  dadosAnterior,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        backgroundColor: '#013494',
        data: [18, 5, 19, 27, 29, 19, 20],
        label: 'MÊS ATUAL'
      }
    ],
    labels: ['Janderly', 'João', 'Raimundo', 'Cristiana', 'José', 'Maycon']
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const dataTwo = {
    datasets: [
      {
        backgroundColor: '#013494',
        data: [...dadosAtual],
        label: 'MÊS ATUAL'
      },
      {
        backgroundColor: '#d3ac57',
        data: [...dadosAnterior],
        label: 'MÊS ANTERIOR'
      }
    ],
    labels: [...funcionarios]
  };

  const optionsTwo = {
    legend: { display: true },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          barPercentage: 1,
          ticks: {
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: '#f0f0f0',
      bodyFontColor: '#000',
      borderColor: '#444',
      borderWidth: 2,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={titleGraf} />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar
            data={type === '1' ? data : dataTwo}
            options={type === '1' ? options : optionsTwo}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
