import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import showSnack from '../../../../utils/snacks';
import api from '../../../../services/data';

export default function Logout({ open, setOpen }) {
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const logout = () => {
    api
      .post(
        'api/auth/logout',
        {}
      )
      .then(({ data }) => {
        if (data.status === 'ok') {
          sessionStorage.removeItem('@N#P#U@');
          sessionStorage.removeItem('@Account#Sessio#MM@');
          sessionStorage.removeItem('@Account#Remem#MM@');
          sessionStorage.removeItem('@Account#Permi#MM@');
          sessionStorage.removeItem('notify');
          history.push('/');
          showSnack('Saída com sucesso', enqueueSnackbar);
        } else {
          showSnack(
            'Ocorreu um erro ao sair, informe ao suporte!',
            enqueueSnackbar,
            'warning'
          );
        }
      })
      .catch(() => {
        showSnack('Erro ao sair da plataforma!', enqueueSnackbar, 'error');
        history.push('/');
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle style={{ background: '#08318e' }}>
        <Typography
          style={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 25,
            textAlign: 'center'
          }}
        >
          Sair do Sistema
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 555,
            marginBottom: 10
          }}
        >
          Você realmente deseja sair da plataforma?
        </Typography>
      </DialogContent>

      <DialogActions className="footer-dialog-cad-unidade">
        <Button
          onClick={() => {
            logout();
          }}
          color="primary"
          className="button-register"
        >
          Sim
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
          className="button-back"
        >
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
}
