import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from 'src/services/data';

import DialogLoading from '../../../components/DialogLoading';

export default function UsuariosFormEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const [dateCad, setDateCad] = useState('');

  const [openLoading, setOpenLoading] = useState(true);

  const [pessoas, setPessoas] = useState([]);
  const [perfis, setPerfis] = useState([]);

  const [pessoa, setPessoa] = useState('');
  const [perfil, setPerfil] = useState('');
  const [emailRecuperacao, setEmailRecuperacao] = useState('');
  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');

  useEffect(() => {
    api
      .get('api/acesso/pessoas/users')
      .then(({ data }) => {
        setPessoas(data);
      })
      .catch(e => {
        console.log(e);
      });

    api
      .get('api/acesso/lista/perfis')
      .then(({ data }) => {
        setPerfis(data);
      })
      .catch(e => {
        console.log(e);
      });

    api
      .get(`api/acesso/lista_one/usuario/${id}`)
      .then(({ data }) => {
        setPessoa(data.id_pessoa);
        setPerfil(data.id_perfil);
        setNome(data.name);
        setEmailRecuperacao(data.mail_recuperacao);

        let cad = new Date(data.created_at).toISOString().split('T')[0].split('-');
        cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

        setDateCad(cad);

        setOpenLoading(false);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const salvar = () => {
    if (pessoa && perfil && emailRecuperacao && nome) {
      let continua = false;

      if (senha || confirmSenha) {
        if (senha === confirmSenha) {
          continua = true;
        } else {
          ShowSnack('Senhas não são iguais !', enqueueSnackbar, 'warning');
        }
      } else {
        continua = true;
      }

      if (continua) {
        api
          .put(
            `/api/acesso/atualizar/usuario/${id}`,
            {
              pessoa,
              perfil,
              emailRecuperacao,
              nome,
              senha
            }
          )
          .then(({ data }) => {
            ShowSnack(data.message, enqueueSnackbar);
            history.push('/app/usuarios-list');
          })
          .catch(e => {
            ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
          });
      }
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  return (
    <>
      <Container className="area-title">
        <Grid container>
          <Grid item xs={12} className="grid-title">
            <Typography className="title">
              Formulário de atualizar usuário
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="area-components">
        <Grid container>
          <Grid item xs={12} md={4} className="grid-campos">
            <TextField
              fullWidth
              variant="outlined"
              value={dateCad}
              disabled
              label="Data de Cadastrado"
            />
          </Grid>

          <Grid item xs={12} md={4} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Pessoa:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Pessoa:"
                value={pessoa}
                onChange={e => {
                  setPessoa(e.target.value);
                }}
              >
                {pessoas.map(dados => {
                  return (
                    <MenuItem value={dados.id} key={dados.id}>
                      {dados.razao_nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Perfil:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Perfil:"
                value={perfil}
                onChange={e => {
                  setPerfil(e.target.value);
                }}
              >
                {perfis.map(dados => {
                  return (
                    <MenuItem value={dados.id} key={dados.id}>
                      {dados.nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              type="email"
              fullWidth
              id="outlined-basic"
              label="Email de Recuperação:"
              variant="outlined"
              value={emailRecuperacao}
              onChange={e => {
                setEmailRecuperacao(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nome de Usuário:"
              variant="outlined"
              value={nome}
              onChange={e => {
                setNome(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Digite a senha:"
              variant="outlined"
              value={senha}
              onChange={e => {
                setSenha(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Re-Digite a senha:"
              variant="outlined"
              value={confirmSenha}
              onChange={e => {
                setConfirmSenha(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Button className="button-register" onClick={() => salvar()}>
              Cadastrar
            </Button>
          </Grid>
        </Grid>
      </Container>

      <DialogLoading open={openLoading} />
    </>
  );
}
