import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DialogLoading({ open }) {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}
