import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';
import api from '../../services/data'

export default function DialogReloadSession({ open, setOpen }) {
  const { username, lock } = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const { enqueueSnackbar } = useSnackbar();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const acessar = () => {
    if (username && password) {
      api
        .post('api/auth/reload_session', {
          name: username,
          password
        })
        .then(({ data }) => {
          if (data.status === 'ok') {
            const accessToken = data.access_token;
            const userIdentify = data.data[0].user_block;
            const perfilIdentify = data.data[0].ok;
            const userName = data.data[0].user;
            const timeSession = new Date().getTime() + 3600000;

            api
              .post(
                'api/auth/logout',
                {}
              )
              .then(({ data }) => {
                if (data.status === 'ok') {
                  sessionStorage.removeItem('@Account#Sessio#MM@');
                  sessionStorage.removeItem('@Account#Remem#MM@');
                  sessionStorage.removeItem('notify');
                  
                  sessionStorage.setItem(
                    '@Account#Sessio#MM@',
                    JSON.stringify({
                      lock: accessToken,
                      user_block: userIdentify,
                      username: userName,
                      time_session: timeSession
                    })
                  );

                  sessionStorage.setItem(
                    '@Account#Remem#MM@',
                    JSON.stringify({
                      remem: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.${perfilIdentify}Ufgw&ey6Fe0jnm9E$E40Js.OiJKOiJIUzI1NiJnm9e0jn`
                    })
                  );

                  setOpen(false);
                  showSnack('Usuário confirmado!', enqueueSnackbar);
                  setPassword('');
                } else {
                  showSnack(
                    'Ocorreu um erro ao sair, informe ao suporte!',
                    enqueueSnackbar,
                    'warning'
                  );
                }
              })
              .catch(() => {
                showSnack('Erro ao sair da plataforma!', enqueueSnackbar, 'error');
              });
          }
        })
        .catch(() => {
          showSnack('Senha incorreta!', enqueueSnackbar, 'error');
        });
    } else {
      showSnack('Preencha todos campos!', enqueueSnackbar, 'warning');
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 20,
              textAlign: 'center'
            }}
          >
            Confirmar a Senha
          </Typography>

          <Typography
            style={{
              color: '#cee245',
              fontWeight: 'bold',
              fontSize: 12,
              textAlign: 'center'
            }}
          >
            Necessário confirmar a senha de login
          </Typography>
        </DialogTitle>

      <DialogContent>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 10 }}
          required
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Senha:
          </InputLabel>
          <OutlinedInput
            id="senha"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={65}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                acessar();
              }
            }}
          />
        </FormControl>

        <Button
          fullWidth
          variant="contained"
          style={{ background: '#013494', color: '#fff', marginTop: 10 }}
          onClick={() => {
            acessar();
          }}
        >
          Confirmar
        </Button>
      </DialogContent>
    </Dialog>
  )
}
