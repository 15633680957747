import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    areaTime: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    titleTime: {
      color: '#08318e',
      fontWeight: 'bold',
      fontSize: 14
    },
    time: {
      width: '100%',
      padding: 10,
      borderRadius: 4,
      color: '#f2f4f5',
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center'
    },
    areaDetails: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    detailsTitle: {
      color: '#08318e',
      fontWeight: 'bold',
      fontSize: 14
    },
    details: {
      marginTop: 10,
      color: '#444',
      fontSize: 14,
      fontWeight: 'bold'
    },
    areaMotivo: {
      border: '1px solid #08318e',
      borderRadius: 4,
      padding: 8,
      width: '100%'
    },
    motivo: {
      fontSize: 14,
      fontWeight: 700,
      color: '#444'
    }
  }));

export default function Informacoes({
    detalhes,
    timeFinished
}) {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.areaTime}>
                <Typography className={classes.titleTime}>
                    Tempo de Atendimento:
                </Typography>

                <Typography
                    className={classes.time}
                    style={{
                        background: timeFinished.color
                    }}
                >
                    {timeFinished.timeFormat}
                </Typography>
            </Box>

            <Box className={classes.areaDetails}>
                <Typography className={classes.detailsTitle}>Protocolo:</Typography>
                <Typography className={classes.details}>
                    {detalhes?.protocolo}
                </Typography>
            </Box>

            <Box className={classes.areaDetails}>
                <Typography className={classes.detailsTitle}>Atendente:</Typography>
                <Typography className={classes.details}>
                {detalhes?.atendente}
                </Typography>
            </Box>

            <Box className={classes.areaDetails}>
                <Typography className={classes.detailsTitle}>Cliente:</Typography>
                <Typography className={classes.details}>
                {detalhes?.cliente}
                </Typography>
            </Box>

            <Box className={classes.areaDetails}>
                <Typography className={classes.detailsTitle}>
                    Solicitante:
                </Typography>
                <Typography className={classes.details}>
                    {detalhes.solicitante ? detalhes.solicitante : '-'}
                </Typography>
            </Box>

            <Box className={classes.areaDetails}>
                <Typography className={classes.detailsTitle}>Status:</Typography>
                <Typography className={classes.details}>
                    {detalhes?.statusFormat}
                </Typography>
            </Box>

            <Box className={classes.areaTime}>
                <Typography className={classes.titleTime}>Motivo:</Typography>
                <Box className={classes.areaMotivo}>
                <Typography className={classes.motivo}>
                    {detalhes?.motivo}
                </Typography>
                </Box>
            </Box>

            {detalhes.resolucao && (
                <Box className={classes.areaTime} style={{ marginTop: 10 }}>
                    <Typography className={classes.titleTime}>Resolucao:</Typography>
                    <Box className={classes.areaMotivo}>
                        <Typography className={classes.motivo}>
                            {detalhes?.resolucao}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    )
}