import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import MessageEmpty from 'src/components/MessageEmpty';

import api from 'src/services/data';

export default function DialogSelectProdutoServico({
    open,
    setOpen,
    itensSelecionados,
    setItensSelecionados,
    tipoSelecao = 'produtos'
}) {
    const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));

    const [produtos, setProdutos] = useState([]);
    const [produtosFiltrados, setProdutosFiltrados] = useState([]);
    
    const handleFilter = (texto) => {
        setProdutosFiltrados(
            produtos.filter(produto => 
                produto.nome.toLowerCase().includes(texto.toLowerCase())
            )
        )
    }

    const handleSelectedItems = (value) => () => {
        const currentIndex = itensSelecionados.indexOf(value);
        const newItemsSelected = [...itensSelecionados];
    
        if (currentIndex === -1) {
          newItemsSelected.push(value);
        } else {
          newItemsSelected.splice(currentIndex, 1);
        }
    
        setItensSelecionados(newItemsSelected);
      };

    useEffect(() => {
        if (open) {
            if (tipoSelecao === 'produtos') {
                api
                .get('api/acesso/lista/produtos')
                .then(({data}) => {
                    setProdutos(data);
                    setProdutosFiltrados(data);
                })
            } else {
                api
                .get('api/acesso/lista/servicos')
                .then(({data}) => {
                    setProdutos(data);
                    setProdutosFiltrados(data);
                })
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                <Typography variant="subtitle2">
                    Selecionar {tipoSelecao === 'produtos' ? 'Produtos' : 'Serviços'}:
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Box>
                    <TextField
                        variant="outlined"
                        label="Pesquisar:"
                        placeholder="Digite o nome do produto..."
                        fullWidth
                        onChange={(e) => handleFilter(e.target.value)}
                    />    
                </Box>

                <List>
                    {
                        produtosFiltrados.length > 0 ? (
                            <>
                                {produtosFiltrados.map((produto) => {
                                    const value = {
                                        id_item: produto.id,
                                        nome: produto.nome,
                                        qtd: 1
                                    };

                                    
                                    const labelId = `checkbox-list-label-${produto.id}`;
                                    const isChecked = itensSelecionados.filter(item => item.id_item === produto.id).length > 0;

                                    return (
                                        <ListItem key={produto.id} dense button onClick={handleSelectedItems(value)}>
                                            <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={isChecked}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={produto.nome} />
                                        </ListItem>
                                    );
                                })}
                            </>
                        ) : <MessageEmpty />
                    }
                </List>
            </DialogContent>
        </Dialog>
    )
}