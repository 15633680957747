export function getTimeSuportFinished(dateStart, dateFinish) {
  const start = new Date(dateStart).getTime();
  const finish = new Date(dateFinish).getTime();

  const milissegundos = finish - start;
  const time = milissegundos / 1000;

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;
  let secondsFormat = String(seconds).split('.')[0];
  secondsFormat = secondsFormat.padStart(2, '0');
  const minutesFormat = String(minutes).padStart(2, '0');
  const hoursFormat = String(hours).padStart(2, '0');

  // const backgroundColorTime = time >= 3600 ? '#f92e35' : '#007ad9';

  const backgroundColorTime =
    time <= 1800
      ? '#21c775'
      : time > 1800 && time <= 3600
      ? '#cab518'
      : '#bd0f0f';

  return {
    color: backgroundColorTime,
    timeFormat: `${hoursFormat}:${minutesFormat}:${secondsFormat}`
  };
}
