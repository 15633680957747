import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Info from '@material-ui/icons/Info';

import { Alert } from '@material-ui/lab';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';

import api from '../../../services/data';

export default function ClientesList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));

  const [pessoas, setPessoas] = useState([]);

  const [open, setOpen] = useState(false);
  const [openDetalhes, setOpenDetalhes] = useState(false);

  const [id, setId] = useState('');
  const [deletado, setDeletado] = useState(false);
  const [detalhes, setDetalhes] = useState([]);

  const del = () => {
    api
      .delete(`/api/acesso/deletar/pessoa/${id}`, {
        params: { will_deleted: 1 }
      })
      .then(({ data }) => {
        ShowSnack(data.message, enqueueSnackbar);
        setDeletado(!deletado);
        setOpen(false);
      })
      .catch(e => {
        ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
      });
  };

  useEffect(() => {
    if (
      permissoes.permi.vis_pessoas != 1 &&
      permissoes.permi.cad_pessoas != 1 &&
      permissoes.permi.edi_pessoas != 1 &&
      permissoes.permi.del_pessoas != 1 &&
      permissoes.permi.vis_pessoas != 1
    ) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    api
      .get('api/acesso/lista/pessoas')
      .then(({ data }) => {
        const results = data.map(dados => {
          return {
            nome: dados.razao_nome,
            apelido: dados.fantasia_apelido ? dados.fantasia_apelido : '-',
            email: dados.email ? dados.email : '-',
            celular: dados.celular ? dados.celular : '-',
            btnAcoes: (
              <>
                <IconButton
                  title="Mais Detalhes"
                  style={{ color: '#6420cfd9' }}
                  onClick={() => {
                    setDetalhes(dados);
                    setOpenDetalhes(true);
                  }}
                >
                  <Info />
                </IconButton>

                {permissoes.permi.edi_pessoas === 1 && (
                  <IconButton
                    title="Atualizar Informações"
                    style={{ color: '#e26912' }}
                    onClick={() => {
                      history.push(`/app/pessoas-form-edit/${dados.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}

                {permissoes.permi.del_pessoas === 1 && (
                  <IconButton
                    title="Excluir"
                    style={{ color: '#bc0910' }}
                    onClick={() => {
                      setId(dados.id);
                      setOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </>
            )
          };
        });

        setPessoas(results);
      })
      .catch(e => {
        console.log(e);
      });
  }, [deletado]);

  return (
    <>
      <Container>
        <Grid container className="area-title">
          <Grid item xs={12} className="grid-title">
            <Typography className="title">Listagem de Pessoas</Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container>
          {permissoes.permi.cad_pessoas === 1 && (
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className="btnPrimary"
                onClick={() => {
                  history.push('/app/pessoas-form');
                }}
                startIcon={<Add />}
              >
                Novo
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            {permissoes.permi.vis_pessoas === 1 ? (
              <DataTable
                value={pessoas}
                paginator
                rows={10}
                responsive
                emptyMessage="Nenhum registro cadastrado..."
                style={{ textAlign: 'center' }}

                rowHover
                currentPageReportTemplate="Mostrando {first} ao {last} de {totalRecords} registros"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
                rowsPerPageOptions={[10,25,50]}
              >
                <Column field="nome" header="Razão/Nome" filter></Column>
                <Column
                  field="apelido"
                  header="Fantasia/Apelido"
                  filter
                ></Column>
                <Column field="email" header="E-mail" filter></Column>
                <Column field="celular" header="Celular" filter></Column>
                <Column field="btnAcoes" header="Ações"></Column>
              </DataTable>
            ) : (
              <Typography
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  marginTop: 10,
                  fontWeight: 'bold',
                  padding: 10,
                  width: '100%',
                  background: '#d09d0f',
                  color: '#222',
                  borderRadius: 4,
                  fontSize: 20
                }}
              >
                Para editar e excluir é necessário a permissão de visualizar
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Confirmar Exclução
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 555,
              marginBottom: 10
            }}
          >
            Você realmente deseja excluir estes dados?
          </Typography>
          <Alert variant="filled" severity="warning">
            Ao confirmar todos estes dados serão excluídos e não poderão ser
            recuperados.
          </Alert>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              del();
            }}
            color="primary"
            className="button-register"
          >
            Confirmar
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            className="button-back"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetalhes}
        onClose={() => {
          setOpenDetalhes(false);
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            + Informações
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Box className="boxInfo">
            <Typography className="textInfo">Cadastrado em:</Typography>

            <Typography className="fontInfo">{moment(detalhes.created_at).format('DD/MM/YYYY HH:mm')}</Typography>
          </Box>
          
          <Box className="boxInfo">
            <Typography className="textInfo">Nome/Razão:</Typography>

            <Typography className="fontInfo">{detalhes.razao_nome}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Fantasia/Apelido:</Typography>
            <Typography className="fontInfo">{detalhes.fantasia_apelido}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Email:</Typography>
            <Typography className="fontInfo">{detalhes.email}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Telefone:</Typography>
            <Typography className="fontInfo">{detalhes.telefone}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Celular:</Typography>
            <Typography className="fontInfo">{detalhes.celular}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Cep:</Typography>
            <Typography className="fontInfo">{detalhes.cep}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Rua:</Typography>
            <Typography className="fontInfo">{detalhes.logradouro}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Bairro:</Typography>
            <Typography className="fontInfo">{detalhes.bairro}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Nº:</Typography>
            <Typography className="fontInfo">{detalhes.numero}</Typography>
          </Box>

          {detalhes.comp && (
            <Box className="boxInfo">
              <Typography className="textInfo">Complemento:</Typography>
              <Typography className="fontInfo">{detalhes.complemento}</Typography>
            </Box>
          )}

          <Box className="boxInfo">
            <Typography className="textInfo">UF:</Typography>
            <Typography className="fontInfo">{detalhes.uf}</Typography>
          </Box>

          {detalhes.obs && (
            <Box className="obsInfo">
              <Typography className="textObsInfo">
                {detalhes.obs}
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              setOpenDetalhes(false);
            }}
            color="primary"
            className="button-back"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
