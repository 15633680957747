import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../utils/snacks';

import TodasIndicacoes from './components/TodasIndicacoes';
import MinhasIndicacoes from './components/MinhasIndicacoes';

export default function Indicacoes() {
  const { enqueueSnackbar } = useSnackbar();
  const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));
  const history = useHistory();

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (
      permissoes.permi.todas_indicacoes != 1 &&
      permissoes.permi.individuais_indicacoes != 1
    ) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    if (
      !permissoes.permi.todas_indicacoes &&
      permissoes.permi.individuais_indicacoes
    ) {
      setValue(1);
    }
  }, [permissoes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Container maxWidth="xl">
      {
        permissoes.permi.todas_indicacoes &&
        permissoes.permi.individuais_indicacoes ? (
          <Grid item xs={12}>
            <Paper style={{ background: '#cecece' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {
                  permissoes.permi.todas_indicacoes && (
                    <Tab
                      label="Todas Indicações"
                      id="simple-tab-0"
                      aria-controls="simple-tabpanel-0"
                    />
                  )
                }

                {
                  permissoes.permi.individuais_indicacoes && (
                    <Tab
                      label="Minhas Indicações"
                      id="simple-tab-1"
                      aria-controls="simple-tabpanel-1"
                    />
                  )
                }
              </Tabs>
            </Paper>
          </Grid>
        ) : <></>
      }

      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <TodasIndicacoes />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <MinhasIndicacoes />
        </TabPanel>
      </Grid>
    </Container>
  );
}