import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Info from '@material-ui/icons/Info';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';

import api from '../../../services/data';

import Details from '../Components/Details';

function Finalizados() {
    const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
    const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));
    const { enqueueSnackbar } = useSnackbar();
    const [atendimentosByUser, setAtendimentosByUser] = useState([]);

    const [openDetails, setOpenDetails] = useState(false);
    const [detalhesAtendimento, setDetalhesAtendimento] = useState([]);

    useEffect(() => {
        if (permissoes.permi.finalizados_atendimentos) {
            api.get(
                `api/acesso/lista/by-user-finalizado/${sessao.user_block}`
            )
            .then(({data}) => {
                const getAtendimentosByUser = data.reverse().map(atendimento => {
                    const status = (
                        <FormLabel
                                style={{
                                    background: atendimento.status === 'aberto' ? '#007ad9' : atendimento.status === 'pausado' ? '#d95c00' : '#24b324',
                                    color: '#fff',
                                    padding: '5px 10px',
                                    borderRadius: 2,
                                    fontWeight: 700,
                                }}
                            >
                                {atendimento.status}
                            </FormLabel>
                    );


                    return {
                        ...atendimento,
                        statusFormat: status,
                        btnAcoes: (
                            <>
                                <IconButton
                                    onClick={() => {
                                        setOpenDetails(true);
                                        setDetalhesAtendimento({...atendimento, statusFormat: status});
                                    }}>
                                    <Info style={{ color: '#6420cfd9' }}/>
                                </IconButton>
                            </>
                        )
                    }
                });

                setAtendimentosByUser(getAtendimentosByUser);
            })
            .catch(() => {
                ShowSnack('[Erro]: Houve um erro com o servidor. Informe ao suporte!', enqueueSnackbar, 'error');
            })
        }
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
            {
                permissoes.permi.finalizados_atendimentos ? (
                        <DataTable
                            paginator
                            rows={10}
                            responsive
                            emptyMessage="Nenhum registro cadastrado..."
                            style={{ textAlign: 'center' }}
                            value={atendimentosByUser}
                        >
                            <Column field="protocolo" header="Nº de Protocolo" filter filterMatchMode="contains" />
                            <Column field="pessoa.nome" header="Cliente" filter filterMatchMode="contains" />
                            <Column field="statusFormat" header="Status"  />
                            <Column field="btnAcoes" header="Ações" />
                        </DataTable>
                ) : (
                    <Typography 
                        style={{
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            marginTop: 10,
                            fontWeight: 'bold',
                            padding: 10,
                            width: '100%',
                            background: '#d09d0f',
                            color: '#222',
                            borderRadius: 4,
                            fontSize: 20
                        }}
                    >
                            Sem permissão para VISUALIZAÇÃO
                    </Typography>
                )
            }
            </Grid>

            <Details
                open={openDetails}
                setOpen={setOpenDetails}
                detalhes={detalhesAtendimento}
                setDetalhes={setDetalhesAtendimento}
            />
        </Grid>
    );
}

export default Finalizados;
