import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import Close from '@material-ui/icons/Close';

import PessoasForm from 'src/views/Pessoas/PessoasForm';

export default function DialogNovaIndicacao({
    open,
    reload,
    setOpen,
    setReload
}) {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullScreen
        >
            <DialogTitle>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Close />}
                    onClick={() => setOpen(false)}
                >Fechar</Button>
            </DialogTitle>
            <DialogContent>
                <PessoasForm
                    isIndication={true}
                    reload={reload}
                    setOpen={setOpen}
                    setReload={setReload}
                />
            </DialogContent>
        </Dialog>
    )
}