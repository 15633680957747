import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import { getTimeSuportFinished } from '../../../../../../utils/getTimeSuportFinished';

import './styles.css';

export default function CardInfoAtendimento({ atendimento }) {
  return (
    <Grid item xs={12} className="area_detalhe_relatorio_atendimento" >
      <Grid container>
        {/* PRIMEIRA LINHA */}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Data/Hora</p>
                <p>{moment.utc(atendimento.created_at).format('DD/MM/YYYY H:mm')}</p>
              </Box>
            </Grid>

            <Grid item xs={4} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Nº Protocolo</p>
                <p>{atendimento.protocolo ? atendimento.protocolo : '-'}</p>
              </Box>
            </Grid>

            <Grid item xs={4} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Solicitante/Contato</p>
                <p>{atendimento.solicitante ? atendimento.solicitante : '-'}</p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        
        {/* SEGUNDA LINHA */}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Tempo de Atendimento</p>
                <p>{getTimeSuportFinished(atendimento.inicio, atendimento?.fim).timeFormat}</p>
              </Box>
            </Grid>

            <Grid item xs={4} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Cliente</p>
                <p>{atendimento.nome_cliente}</p>
              </Box>
            </Grid>

            <Grid item xs={4} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Atendente</p>
                <p>{atendimento.nome_user}</p>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* TERCEIRA LINHA */}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Motivo</p>
                <p>{atendimento.motivo}</p>
              </Box>
            </Grid>

            <Grid item xs={6} style={{ padding: 2 }} >
              <Box className="card_print_detalhe">
                <p>Resolução</p>
                <p>{atendimento.resolucao}</p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
