import React from 'react';

import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './styles.css';

export default function PrintRelatorioSintetico({atendimentos}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#444',
          }}
        >
          Relatório de Atendimento
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: 10,
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#515151',
            paddingBottom: 4,
            borderBottom: '1px dashed #d7d7d7',
            marginBottom: 6
          }}
        >
          Gerado em {moment(new Date()).format('DD/MM/YYYY')} | Total de Registros: { atendimentos.length }
        </Typography>
      </Grid>

      <Grid item xs={12}>
          <table className="table_custom">
            <thead>
              <tr>
                <th>Data/Hora</th>
                <th>Cliente</th>
                <th>Solicitante</th>
                <th>Atendente</th>
              </tr>
            </thead>

            <tbody>
            {atendimentos.map((atendimento) => {
              return (
                <tr>
                  <td>{moment.utc(atendimento.created_at).format('DD/MM/YYYY H:mm')}</td>
                  <td>{atendimento.nome_cliente}</td>
                  <td>{atendimento.solicitante ? atendimento.solicitante : '-'}</td>
                  <td>{atendimento.nome_user}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
      </Grid>
    </Grid>
  )
}
