import React, { useEffect, useState } from 'react';

import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMore from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Delete from '@material-ui/icons/Delete';

import DialogSelectProdutoServico from '../DialogSelectProdutoServico';
import MessageEmpty from 'src/components/MessageEmpty';

import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';
import { moeda } from 'src/utils/masks';

import api from 'src/services/data';

import './styles.css';
import { formatDate } from 'src/utils/formatDate';

export default function DialogAcoesIndicacao({
    open,
    idIndicacao,
    reload,
    setOpen,
    setIdIndicacao,
    setReload,
    withActions = false
}) {
    const { enqueueSnackbar } = useSnackbar();
    const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
    const [informacoes, setInformacoes] = useState({});
    const [statusIndicacao, setStatusIndicacao] = useState([]);
    const [statusIndicacaoSelecionado, setStatusIndicacaoSelecionado] = useState({})

    const [abrirSelecionarProdutos, setAbrirSelecionarProdutos] = useState(false);
    const [abrirSelecionarServicos, setAbrirSelecionarServicos] = useState(false);
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [servicosSelecionados, setServicosSelecionados] = useState([]);
    const [comissoes, setComissoes] = useState([]);
    const [obs, setObs] = useState('');
    
    const onClosed = () => {
        setOpen(false);
        setIdIndicacao('');
        setProdutosSelecionados([]);
        setServicosSelecionados([]);
        setComissoes([]);
        setStatusIndicacaoSelecionado({});
    }
    
    const onSave = () => {
        api.put(
            `api/acesso/indicacoes/atualizar/${idIndicacao}`,
            {
               statusIndicacaoSelecionado,
               produtosSelecionados,
               servicosSelecionados,
               comissoes,
               obs
            }
        ).then(() => {
            onClosed();
            setReload(!reload);
        }).catch(console.log)
    }

    const onValidate = (e) => {
        e.preventDefault();

        if (!statusIndicacaoSelecionado) {
            showSnack('Selecione o status para continuar', enqueueSnackbar, 'warning');
            return;
        }

        if (statusIndicacaoSelecionado.necessario_obs && !obs) {
            showSnack('Campo de motivo é obrigatório', enqueueSnackbar, 'warning');
            return;
        }

        if (statusIndicacaoSelecionado.status_final) {
            if (produtosSelecionados.length <= 0 && servicosSelecionados.length <= 0) {
                showSnack('Necessário selecionar pelo menos 1 produto ou serviço', enqueueSnackbar, 'warning');
                return;
            }

            let numProdutoQtdZero = 0;
            produtosSelecionados.forEach(produto => {
                if (produto.qtd <= 0) {
                    numProdutoQtdZero++;
                }
            });

            if (numProdutoQtdZero) {
                showSnack('Existe produto informado com quatidade menor ou igual a 0', enqueueSnackbar, 'warning');
                return;
            }

            let numServicoQtdZero = 0;
            servicosSelecionados.forEach(servico => {
                if (servico.qtd <= 0) {
                    numServicoQtdZero++;
                }
            });

            if (numServicoQtdZero) {
                showSnack('Existe serviço informado com quatidade menor ou igual a 0', enqueueSnackbar, 'warning');
                return;
            }

            if (comissoes.length <= 0) {
                showSnack('Informe pelo menos 1 parcela de comissão', enqueueSnackbar, 'warning');
                return;
            }

            let numComissaoQtdZero = 0;
            comissoes.forEach(comissao => {
                if (comissao.valor_parcela <= 0) {
                    numComissaoQtdZero++;
                }
            })

            if (numComissaoQtdZero) {
                showSnack('Existe parcela de comissão informada com quatidade menor ou igual a 0', enqueueSnackbar, 'warning');
                return;
            }
        }

        onSave();
    }

    const handleSelecionarStatusIndicacao = (idStatus) => {
        const pegaStatusPorId = statusIndicacao.filter(status => status.id === idStatus);

        setStatusIndicacaoSelecionado(pegaStatusPorId[0]);
        setProdutosSelecionados([]);
        setServicosSelecionados([]);
        setComissoes([]);
        setObs('');
    }

    const handleChangeItemsSelected = (tipo, item, valor) => {
        let arrayWork = tipo === 'produto' ? produtosSelecionados : servicosSelecionados;

        const currentIndex = arrayWork.indexOf(item);

        if (currentIndex !== -1) {
            arrayWork = arrayWork.map(arrItem => {
                return item.id_item === arrItem.id_item
                ? { ...arrItem, qtd: Number(valor) }
                : { ...arrItem };
            })

            if (tipo === 'produto') {
                setProdutosSelecionados(arrayWork);
            } else {
                setServicosSelecionados(arrayWork);
            }
        } else {
            alert('Desculpa, mas não encontrados este item');
        }
    }

    const handleNumParcelas = (tipo) => {
        const current_month = new Date().getMonth() + 1;
        const current_year = new Date().getFullYear();

        if (tipo === 'adicionar') {
            const total_num_parcelas = comissoes.length + 1;

            let total_elementos_parcelas = [];
            let year = current_year;

            for (let i = 0; i < total_num_parcelas; i++) {
                const month = current_month + i > 12 ? (current_month + i) % 12 === 0 ? 12 : (current_month + i) % 12 : current_month + i;
                const increment_year = (current_month + i) / 12;
                
                total_elementos_parcelas = [
                    ...total_elementos_parcelas,
                    {
                        id: i,
                        valor_parcela: 0,
                        data_pagamento: new Date(`${year}-${month}-05`).toISOString().split('T')[0],
                    }
                ]

                year = current_year + Math.floor(increment_year);

            }

            setComissoes(total_elementos_parcelas);
        }
        
        if (tipo === 'diminuir') {
            if (comissoes.length > 0) {
                let dataComissoes = [...comissoes];
                dataComissoes.pop();

                setComissoes(dataComissoes);
            }
        }
    }

    const handleValorParcelaComissao = (parcela, valor) => {
        const novoArrayComissoes = comissoes.map(comissao => {
            return comissao.id === parcela.id
            ? { ...comissao, valor_parcela: moeda(valor)}
            : { ...comissao }
        });

        setComissoes(novoArrayComissoes);
    }

    useEffect(() => {
        if (open && idIndicacao) {
            api.get(`api/acesso/indicacoes/busca-por-id/${idIndicacao}`)
            .then(({ data }) => {
                console.log(data);
                setInformacoes(data[0]);
            }).catch(console.log);

            api.get('api/acesso/status-indicacao')
            .then(({ data }) => {
                setStatusIndicacao(data);
            })
        }
    }, [open]);
    
    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth={!statusIndicacaoSelecionado?.status_final}
            fullScreen={statusIndicacaoSelecionado?.status_final}
            maxWidth="md"
        >
            <form onSubmit={onValidate}>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box className="infos">
                                <Typography variant="h6">Indicação em: <span>{moment(informacoes.created_at).format('DD/MM/YYYY HH:mm')}</span></Typography>
                                <Typography variant="h6">Parceiro: <span>{informacoes.user?.name}</span></Typography>
                                <Typography variant="h6">Cliente: <span>{informacoes.pessoa?.razao_nome}</span></Typography>
                                {
                                    informacoes.obs && (
                                        <Typography variant="h6">
                                            Motivo: <span>{informacoes.obs}</span>
                                        </Typography>
                                    )
                                }
                            </Box>
                        </Grid>
                        
                        {
                            withActions && !informacoes?.status_indicacao?.status_final && (
                                <>
                                    <Grid item xs={12}>
                                        <Box className="divider">
                                            Mudar o Status
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <FormControl required fullWidth variant="outlined">
                                                    <InputLabel>Selecione o status:</InputLabel>

                                                    <Select
                                                        label="Selecione o status:"
                                                        value={statusIndicacaoSelecionado?.id}
                                                        onChange={(e) => handleSelecionarStatusIndicacao(e.target.value)}
                                                    >
                                                        {
                                                            statusIndicacao.map(status => {
                                                                if (!status.status_abertura && status.id !== informacoes.id_status) {
                                                                    return (
                                                                        <MenuItem key={status.id} value={status.id}>{status.titulo}</MenuItem>
                                                                    )        
                                                                }
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {
                                                statusIndicacaoSelecionado?.status_final ? (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Box className="divider">
                                                                Detalhes do Fechamento
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                    style={{ background: '#f2f2f2' }}
                                                                >
                                                                    <Typography>Selecionar Produtos</Typography>
                                                                </AccordionSummary>

                                                                <AccordionDetails className="accordion_details">
                                                                    <Button
                                                                        variant="text"
                                                                        startIcon={<Add />}
                                                                        onClick={() => setAbrirSelecionarProdutos(true)}
                                                                        color="primary"
                                                                    >
                                                                        Adicionar Itens
                                                                    </Button>

                                                                    <Box className="accordion_details">
                                                                        {
                                                                            produtosSelecionados.length > 0 ? (
                                                                                <>
                                                                                    {produtosSelecionados.map(produto => (
                                                                                        <Box className="items" key={produto.id_item}>
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                label="Produto:"
                                                                                                value={produto.nome}
                                                                                                fullWidth
                                                                                                disabled
                                                                                            />
                                                                                        
                                                                                            <Box>
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    label="Qtd:"
                                                                                                    type="number"
                                                                                                    value={produto.qtd <= 0 ? null : produto.qtd}
                                                                                                    onChange={(e) => handleChangeItemsSelected('produto', produto, e.target.value)}
                                                                                                    fullWidth
                                                                                                    required
                                                                                                />

                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    color="secondary"
                                                                                                >
                                                                                                    <Delete />
                                                                                                </Button>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    ))}
                                                                                </>
                                                                            ) : <MessageEmpty textEmpty="Nenhum item selecionado..." />
                                                                        }
                                                                    </Box>
                                                                </AccordionDetails>
                                                            </Accordion>

                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                    style={{ background: '#f2f2f2' }}
                                                                >
                                                                    <Typography>Selecionar Serviços</Typography>
                                                                </AccordionSummary>

                                                                <AccordionDetails className="accordion_details">
                                                                    <Button
                                                                        variant="text"
                                                                        startIcon={<Add />}
                                                                        onClick={() => setAbrirSelecionarServicos(true)}
                                                                        color="primary"
                                                                    >
                                                                        Adicionar Itens
                                                                    </Button>

                                                                    <Box className="accordion_details">
                                                                        {
                                                                            servicosSelecionados.length > 0 ? (
                                                                                <>
                                                                                    {
                                                                                        servicosSelecionados.map(servico => (
                                                                                            <Box className="items" key={servico.id_item}>
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    label="Serviço:"
                                                                                                    value={servico.nome}
                                                                                                    fullWidth
                                                                                                    disabled
                                                                                                />
                                                                                            
                                                                                                <Box>
                                                                                                    <TextField
                                                                                                        variant="outlined"
                                                                                                        label="Qtd:"
                                                                                                        type="number"
                                                                                                        value={servico.qtd <= 0 ? null : servico.qtd}
                                                                                                        onChange={(e) => handleChangeItemsSelected('servico', servico, e.target.value)}
                                                                                                        fullWidth
                                                                                                        required
                                                                                                    />

                                                                                                    <Button
                                                                                                        variant="contained"
                                                                                                        color="secondary"
                                                                                                    >
                                                                                                        <Delete />
                                                                                                    </Button>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                            ) : <MessageEmpty textEmpty="Nenhum item selecionado..." />
                                                                        }
                                                                    </Box>
                                                                </AccordionDetails>
                                                            </Accordion>

                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                    style={{ background: '#f2f2f2' }}
                                                                >
                                                                    <Typography>Definir Comissão</Typography>
                                                                </AccordionSummary>

                                                                <AccordionDetails className="accordion_details">
                                                                    <Box className="area_add_remove_parcela">
                                                                        <Button
                                                                            startIcon={<Add />}
                                                                            color="primary"
                                                                            onClick={() => handleNumParcelas('adicionar')}
                                                                        >
                                                                            Adicionar Parcela
                                                                        </Button>

                                                                        <Button
                                                                            startIcon={<Remove />}
                                                                            color="primary"
                                                                            onClick={() => handleNumParcelas('diminuir')}
                                                                        >
                                                                            Remover Última Parcela
                                                                        </Button>
                                                                    </Box>

                                                                    {
                                                                        comissoes.length > 0 ? (
                                                                            <Box
                                                                                display="flex"
                                                                                flexDirection="column"
                                                                                gridGap={12}
                                                                            >
                                                                                {
                                                                                    comissoes.map((comissao, index) => {
                                                                                        return (
                                                                                            <Box
                                                                                                display="flex"
                                                                                                alignItems="center"
                                                                                                justifyContent="center"
                                                                                                gridGap={12}
                                                                                                key={index}
                                                                                            >
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    value={comissao.valor_parcela}
                                                                                                    onChange={(e) => handleValorParcelaComissao(comissao, e.target.value)}
                                                                                                    label="Valor da comissão:"
                                                                                                    placeholder="Informe o valor.."
                                                                                                    type="text"
                                                                                                    required
                                                                                                />

                                                                                                <TextField  
                                                                                                    variant="outlined"
                                                                                                    value={formatDate(comissao.data_pagamento)}
                                                                                                    label="Data do Pagamento:"
                                                                                                    disabled
                                                                                                />
                                                                                            </Box>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Box>
                                                                        ) : <MessageEmpty textEmpty="Informe o número de parcelas" />
                                                                    }
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    </>
                                                ) : null
                                            }

                                            {
                                                statusIndicacaoSelecionado?.necessario_obs ? (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            label="Motivo:"
                                                            placeholder="Digite o motivo da recusa..."
                                                            multiline
                                                            rows={4}
                                                            rowsMax={4}
                                                            value={obs}
                                                            onChange={(e) => setObs(e.target.value)}
                                                            required
                                                        />
                                                    </Grid>
                                                ) : null
                                            }

                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    Confirmar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>    
                                </>
                            )
                        }
                    </Grid>
                </DialogContent>
            </form>

            <DialogSelectProdutoServico
                open={abrirSelecionarProdutos}
                setOpen={setAbrirSelecionarProdutos}
                itensSelecionados={produtosSelecionados}
                setItensSelecionados={setProdutosSelecionados}
                tipoSelecao="produtos"
            />

            <DialogSelectProdutoServico
                open={abrirSelecionarServicos}
                setOpen={setAbrirSelecionarServicos}
                itensSelecionados={servicosSelecionados}
                setItensSelecionados={setServicosSelecionados}
                tipoSelecao="servicos"
            />
        </Dialog>
    )
}