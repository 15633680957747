import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Page from 'src/components/Page';

import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';
import api from 'src/services/data';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: 'url(/back_login.png)',
    backgroundSize: '100%',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: '#013494'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));

  const [showPassword, setShowPassword] = useState(false);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (sessao?.lock) {
      history.push('/app/dashboard');
    }
  }, []);

  const acessar = () => {
    if (name && password) {
      api
        .post('api/auth/login', {
          name,
          password
        })
        .then(({ data }) => {
          if (password === '123456') {
            sessionStorage.setItem('@N#P#U@', JSON.stringify({ need: 1 }));
          }
          sessionStorage.setItem(
            '@Account#Sessio#MM@',
            JSON.stringify({
              lock: data.access_token,
              user_block: data.data[0].user_block,
              username: data.data[0].user,
              time_session: new Date().getTime() + 3600000
            })
          );
          sessionStorage.setItem(
            '@Account#Remem#MM@',
            JSON.stringify({
              remem: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.${data.data[0].ok}Ufgw&ey6Fe0jnm9E$E40Js.OiJKOiJIUzI1NiJnm9e0jn`
            })
          );
          history.push('/app');
          showSnack('Login feito com sucesso!', enqueueSnackbar);
          setName('');
          setPassword('');
        })
        .catch(() => {
          showSnack('Usuário e/ou senha incorretas!', enqueueSnackbar, 'error');
        });
    } else {
      showSnack('Preencha todos campos!', enqueueSnackbar, 'warning');
    }
  };

  const onValidate = (e) => {
    e.preventDefault();

    if (!name) {
      showSnack('Informe nome de usuário', enqueueSnackbar, 'warning');
      return;
    }

    if (!password) {
      showSnack('Informe a senha', enqueueSnackbar, 'warning');
      return;
    }    

    acessar();
  }

  return (
    <Page className={classes.root} title="Área Restrita">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container
          maxWidth="sm"
          style={{
            border: '1px solid #ddd',
            borderRadius: 4,
            background: '#fff'
          }}
        >
          <form onSubmit={onValidate}>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginTop: 10, marginBottom: 10 }}
              required
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Nome de Usuário:
              </InputLabel>
              <OutlinedInput
                id="usuario"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <AccountCircle style={{ color: '#787878' }} />
                  </InputAdornment>
                }
                labelWidth={150}
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
                required
              />
            </FormControl>

            <TextField
              required
              id="input-with-text-normal"
              label="Senha"
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}         
            />

            <Box my={2}>
              <Button
                fullWidth
                variant="contained"
                style={{ background: '#013494', color: '#fff' }}
                type="submit"
              >
                Acessar
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
