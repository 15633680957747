import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Container, Grid } from '@material-ui/core';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TrafficByDevice from './TrafficByDevice';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../utils/snacks';
import api from '../../services/data';

import Abertos from './Abertos';
import Pausados from './Pausados';
import Finalizados from './Finalizados';
import Total from './Total';

const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const [loadingAtendimentos, setLoadingAtendimentos] = useState(false);
  const [loadingGrafic, setLoadingGrafic] = useState(false);

  const [abertos, setAbertos] = useState(0);
  const [pausados, setPausados] = useState(0);
  const [finalizados, setFinalizados] = useState(0);
  const [total, setTotal] = useState(0);

  const [users, setUsers] = useState([]);
  const [mesAtual, setMesAtual] = useState([]);
  const [mesAnterior, setMesAnterior] = useState([]);

  useEffect(() => {
    setLoadingAtendimentos(true);
    api
      .get('api/acesso/gerente/status')
      .then(({ data }) => {
        setAbertos(
          data.filter(dados => {
            return dados.status === 'aberto';
          }).length
        );
        setPausados(
          data.filter(dados => {
            return dados.status === 'pausado';
          }).length
        );
        setFinalizados(
          data.filter(dados => {
            return dados.status === 'finalizado';
          }).length
        );
        setTotal(data.length);
        setLoadingAtendimentos(false);
      })
      .catch(() => {
        ShowSnack(
          '[Erro]: Houve um erro com o servidor. Informe ao suporte!',
          enqueueSnackbar,
          'error'
        );
      });
  }, []);

  useEffect(() => {
    setLoadingGrafic(true);
    api
      .get('api/acesso/graficos/atendimento-por-usuarios')
      .then(({ data }) => {
        if (data.length > 0) {
          data.map(atendimentos => {
            users.push(atendimentos.usuario.toUpperCase());
            mesAtual.push(atendimentos.mesAtual);
            mesAnterior.push(atendimentos.mesAnterior);
          });
        }
        setLoadingGrafic(false);
      })
      .catch(() => {
        ShowSnack(
          '[Erro]: Houve um erro com o servidor. Informe ao suporte!',
          enqueueSnackbar,
          'error'
        );
      });
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        {loadingAtendimentos || loadingAtendimentos ? (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 50
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Abertos valor={abertos} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Pausados valor={pausados} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Finalizados valor={finalizados} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Total valor={total} />
            </Grid>
            <Grid item xs={12}>
              <Sales
                titleGraf="Comparativo de nº de atendimentos dos funcionários entre o mês atual e o anterior"
                type="2"
                funcionarios={users}
                dadosAtual={mesAtual}
                dadosAnterior={mesAnterior}
              />
            </Grid>
            {/* <Grid item xs={12}>
                <Sales
                  titleGraf="Atendimentos por funcionário no mês atual"
                  type="1"
                />
              </Grid> */}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default Dashboard;
