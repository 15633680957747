import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import api from 'src/services/data';

import './styles.css';

export default function DialogDetalhes({
    open,
    idStatus,
    setOpen,
    setIdStatus
}) {

    const [detalhesStatus, setDetalhesStatus] = useState({
        titulo: '',
        cor: '',
        status_abertura: false,
        status_final: false,
        necessario_obs: false,
    });

    const onClosed = () => {
        setOpen(false);
        setIdStatus('');
        setDetalhesStatus({
            titulo: '',
            cor: '',
            status_abertura: false,
            status_final: false,
            necessario_obs: false,
        });
    }

    useEffect(() => {
        if (open && idStatus) {
            api.get(`api/acesso/status-indicacao/${idStatus}`)
            .then(({ data }) => {
                setDetalhesStatus(data);
            })
            .catch(console.log)
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle className="dialog_title_detalhes_status">
                Detalhes
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Nome:"
                            value={detalhesStatus.titulo}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Cor:"
                            required
                            type="color"
                            value={detalhesStatus.cor}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth disabled>
                            <InputLabel>Status de Abertura?</InputLabel>

                            <Select
                                label="Status de Abertura?"
                                value={detalhesStatus.status_abertura}
                            >
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth disabled>
                            <InputLabel>Status de Fechamento?</InputLabel>

                            <Select
                                label="Status de Fechamento?"
                                value={detalhesStatus.status_final}
                            >
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth disabled>
                            <InputLabel>Necessário Observação?</InputLabel>

                            <Select
                                label="Necessário Observação?"
                                value={detalhesStatus.necessario_obs}
                            >
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}