import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Info from '@material-ui/icons/Info';

import DialogLoading from 'src/components/DialogLoading';

import { useSnackbar } from 'notistack';
import ShowSnack from 'src/utils/snacks';

import api from 'src/services/data';

export default function UsuariosForm() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const params = useParams();
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));
  const [dateCad, setDateCad] = useState('');
  const [isParceiro, setIsParceiro] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [pessoas, setPessoas] = useState([]);
  const [perfis, setPerfis] = useState([]);

  const [pessoa, setPessoa] = useState('');
  const [perfil, setPerfil] = useState('');
  const [emailRecuperacao, setEmailRecuperacao] = useState('');
  const [nome, setNome] = useState('');

  useEffect(() => {
    api
      .get('api/acesso/pessoas/users')
      .then(({ data }) => {
        setPessoas(data);
      })
      .catch(e => {
        console.log(e);
      });

    api
      .get('api/acesso/lista/perfis')
      .then(({ data }) => {
        setPerfis(data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    let cad = new Date().toISOString().split('T')[0].split('-');
    cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

    setDateCad(cad);
  }, []);

  useEffect(() => {
    if (params.infoUsuario) {
      const infos = JSON.parse(window.atob(params.infoUsuario));

      setEmailRecuperacao(infos.email);
      setPessoa(infos.id);
      setIsParceiro(infos.isParceiro);
    }
  }, [params])

  const salvar = () => {
    if (pessoa && perfil && emailRecuperacao && nome) {
      setIsLoading(true);

      api
        .post(
          '/api/acesso/cadastrar/usuario',
          {
            pessoa: pessoa.split(':')[0],
            perfil,
            emailRecuperacao,
            nome
          }
        )
        .then(async ({ data }) => {
          if (data.message.startsWith('[Error]')) {
            ShowSnack('Ocorreu um erro ao cadastrar o usuário', enqueueSnackbar, 'error');
            return;
          }

          if (isParceiro) {
            await api.post('api/acesso/confirma-criacao-usuario-parceiro', {
              username: nome,
              email: emailRecuperacao
            })
            .then(() => setIsLoading(false));
          } else {
            setIsLoading(false);
          }
          
          ShowSnack(data.message, enqueueSnackbar);
          history.push('/app/usuarios-list');
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
          setIsLoading(false);
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  return (
    <>
      <Container className="area-title">
        <Grid container>
          <Grid item xs={12} className="grid-title">
            <Typography className="title">
              Formulário de cadastro de usuário
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="area-components">
        <Grid container>
          <Grid item xs={12} className="grid-campos">
            <Typography
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: "0.9rem",
                color: '#222',
                background: '#05d9d2ad',
                borderRadius: 4,
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Info />
              Será criado uma senha padrão (1 a 6) ao efetuar o login pela
              primeira vez será solicitado a atualização.
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} className="grid-campos">
            <TextField
              fullWidth
              variant="outlined"
              value={dateCad}
              disabled
              label="Data de Cadastrado"
            />
          </Grid>

          <Grid item xs={12} md={4} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Pessoa:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Pessoa:"
                value={pessoa}
                onChange={e => {
                  if (e.target.value.split(':')[1]) {
                    setIsParceiro(true);
                  } else {
                    setIsParceiro(false);
                  }

                  setPessoa(e.target.value);
                }}
              >
                {pessoas.map(dados => {
                  return (
                    <MenuItem value={`${dados.id}:${dados.parceiro}`} key={dados.id}>
                      {dados.razao_nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Perfil:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Perfil:"
                value={perfil}
                onChange={e => {
                  setPerfil(e.target.value);
                }}
              >
                {perfis.map(dados => {
                  return (
                    <MenuItem value={dados.id} key={dados.id}>
                      {dados.nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              type="email"
              fullWidth
              id="outlined-basic"
              label="Email de Recuperação:"
              variant="outlined"
              value={emailRecuperacao}
              onChange={e => {
                setEmailRecuperacao(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nome de Usuário:"
              variant="outlined"
              value={nome}
              onChange={e => {
                setNome(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Button className="button-register" onClick={() => salvar()}>
              Cadastrar
            </Button>
          </Grid>
        </Grid>

        <DialogLoading open={isLoading}/>
      </Container>
    </>
  );
}
