import React from 'react';

import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CardInfoAtendimento from './CardInfoAtendimento';

export default function PrintRelatorioAtendimento({atendimentos}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#444',
          }}
        >
          Relatório de Atendimento
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: 10,
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#515151',
            paddingBottom: 4,
            borderBottom: '1px dashed #d7d7d7',
            marginBottom: 6
          }}
        >
          Gerado em {moment(new Date()).format('DD/MM/YYYY')} | Total de Registros: { atendimentos.length }
        </Typography>
      </Grid>

      {atendimentos.map((atendimento) => {
        return (
          <>
            <CardInfoAtendimento atendimento={atendimento} />
            
            <div
              style={{
                margin: '10px 0px',
                border: '1px dashed #d7d7d7',
                height: 2,
                width: '100%'
              }}
            />
          </>
        )
      })}
    </Grid>
  )
}
