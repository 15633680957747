import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Add from '@material-ui/icons/Add';

import Pendentes from './Pendentes';
import Confirmados from './Confirmados';

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../../utils/snacks';
import DialogNovaIndicacao from './DialogNovaIndicacao';

import './styles.css';
import api from 'src/services/data';

export default function MinhasIndicacoes() {
  const permissoes = JSON.parse(sessionStorage.getItem('@Account#Permi#MM@'));
  const sessao = JSON.parse(sessionStorage.getItem('@Account#Sessio#MM@'));

  const { status } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [reload, setReload] = useState(false);

  const [value, setValue] = useState(0);
  const [saldo, setSaldo] = useState(0);

  const [openNovaIndicacao, setOpenNovaIndicacao] = useState(false);

  useEffect(() => {
    if (
      permissoes.permi.novo_atendimentos != 1 &&
      permissoes.permi.pausar_atendimentos != 1 &&
      permissoes.permi.continuar_atendimentos != 1 &&
      permissoes.permi.finalizar_atendimentos != 1 &&
      permissoes.permi.ematendimento_atendimentos != 1 &&
      permissoes.permi.finalizados_atendimentos != 1 &&
      permissoes.permi.acompanhar_atendimentos != 1
    ) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    if (status) {
      setValue(2);
    }
  }, []);

  useEffect(() => {
    api.get(`api/acesso/indicacoes/comissao/${sessao.user_block}`)
      .then(({ data }) => {
        setSaldo(data.valorTotal);
      })
      .catch(console.log)
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box className="my_balance">
              <Typography className="title_balance">Meu Saldo:</Typography>
              <Typography className="balance">R$ {Number(saldo).toFixed(2)}</Typography>
              <Typography className="balance_info">Compensação dia 05</Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setOpenNovaIndicacao(true)}
          >
            Nova Indicação
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ background: '#cecece' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label="Pendentes"
                id="simple-tab-1"
                aria-controls="simple-tabpanel-1"
              />
              <Tab
                label="Confirmados"
                id="simple-tab-0"
                aria-controls="simple-tabpanel-0"
              />
            </Tabs>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <Pendentes reload={reload} setReload={setReload}/>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Confirmados />
          </TabPanel>
        </Grid>
      </Grid>

      <DialogNovaIndicacao
        open={openNovaIndicacao}
        reload={reload}
        setOpen={setOpenNovaIndicacao}
        setReload={setReload}
      />
    </Container>
  );
}
